import { useDispatch } from 'react-redux'
import { setStakingValue } from '../stakingSlices/ModalsSlice'
import './DragScale.scss'
import Slider from 'rc-slider'
import { handleRender } from './DragTooltip'
import { useMediaQuery } from 'react-responsive'

export default function DragScale({ value }: { value: number }) {
  const dispatch = useDispatch()

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  return (
    <section className="DragScale_container">
      <Slider
        vertical={isMobile}
        handleRender={(node, props) =>
          handleRender(node, {
            ...props,
            value: (value * props.value) / 100,
          })
        }
        onChange={(val) => {
          dispatch(
            setStakingValue(Number(((value * Number(val)) / 100).toFixed(0))),
          )
        }}
        marks={{
          0: {
            style: {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '32px',
              color: '#c4c4c4',
              marginLeft: isMobile ? 4 : 0,
            },
            label: <p>0</p>,
          },
          20: {
            style: {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#c4c4c4',
              marginTop: 5,
              marginLeft: isMobile ? 4 : 0,
            },
            label: (
              <p>
                {value * 0.2 >= 1e3 && value * 0.2 < 1e6
                  ? ((value * 0.2) / 1e3).toFixed(1)
                  : value * 0.2 >= 1e6
                  ? ((value * 0.2) / 1e6).toFixed(1)
                  : (value * 0.2).toFixed(1)}{' '}
                <span style={{ color: '#ffba44', fontSize: 12 }}>(20%)</span>
              </p>
            ),
          },
          40: {
            style: {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#c4c4c4',
              marginTop: 5,
              marginLeft: isMobile ? 4 : 0,
            },
            label: (
              <p>
                {value * 0.4 >= 1e3 && value * 0.4 < 1e6
                  ? ((value * 0.4) / 1e3).toFixed(1)
                  : value * 0.4 >= 1e6
                  ? ((value * 0.4) / 1e6).toFixed(1)
                  : (value * 0.4).toFixed(1)}{' '}
                <span style={{ color: '#ffba44', fontSize: 12 }}>(40%)</span>
              </p>
            ),
          },
          60: {
            style: {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#c4c4c4',
              marginTop: 5,
              marginLeft: isMobile ? 4 : 0,
            },
            label: (
              <p>
                {value * 0.6 >= 1e3 && value * 0.6 < 1e6
                  ? ((value * 0.6) / 1e3).toFixed(1)
                  : value * 0.6 >= 1e6
                  ? ((value * 0.6) / 1e6).toFixed(1)
                  : (value * 0.6).toFixed(1)}{' '}
                <span style={{ color: '#ffba44', fontSize: 12 }}>(60%)</span>
              </p>
            ),
          },
          80: {
            style: {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#c4c4c4',
              marginTop: 5,
              marginLeft: isMobile ? 4 : 0,
            },
            label: (
              <p>
                {value * 0.8 >= 1e3 && value * 0.8 < 1e6
                  ? ((value * 0.8) / 1e3).toFixed(1)
                  : value * 0.8 >= 1e6
                  ? ((value * 0.8) / 1e6).toFixed(1)
                  : (value * 0.8).toFixed(1)}{' '}
                <span style={{ color: '#ffba44', fontSize: 12 }}>(80%)</span>
              </p>
            ),
          },
          100: {
            style: {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '32px',
              color: '#c4c4c4',
              marginLeft: isMobile ? 4 : 0,
            },
            label: (
              <p>
                {value >= 1e3 && value < 1e6
                  ? (value / 1e3).toFixed(2)
                  : value >= 1e6
                  ? (value / 1e6).toFixed(2)
                  : value.toFixed(2)}
              </p>
            ),
          },
        }}
      />
    </section>
  )
}

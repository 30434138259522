function formatDurationFromHex(hexValue) {
  // Extracting the decimal value from the hex
  const decimalValue = parseInt(hexValue._hex)

  // Converting the decimal value to human-readable format
  return formatDuration(decimalValue)
}

// Function to format duration
function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = durationInSeconds % 60

  let result = ''

  if (hours > 0) {
    result += `${hours} hour${hours !== 1 ? 's' : ''}`
  }

  if (minutes > 0) {
    result += `${result !== '' ? ', ' : ''}${minutes} minute${
      minutes !== 1 ? 's' : ''
    }`
  }

  if (seconds > 0) {
    result += `${result !== '' ? ', ' : ''}${seconds} second${
      seconds !== 1 ? 's' : ''
    }`
  }

  return result
}

export { formatDurationFromHex }

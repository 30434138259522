import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import './Buttons.scss'
import { setStakeModalVisible } from '../stakingSlices/ModalsSlice'

export default function GoToDashboardButton({
  width,
  text,
  onClick,
}: {
  width?: number
  text?: string
  onClick?: () => void
}) {
  let width2: number
  width2 = (width / 1920) * 100

  const modal = useSelector((state: AppState) => state.stakingModals)
  const dispatch = useDispatch()

  return (
    <button
      onClick={() => {
        // window.open('/profile', '_self')
        if (onClick) {
          onClick()
        } else {
          dispatch(setStakeModalVisible(true))
        }
      }}
      className="GoToDashboardButton"
    >
      {text ? text : 'CANCEL'}
    </button>
  )
}

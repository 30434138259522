import { Coin } from "assets"
import { GET_KNIGHT_ITEMS } from "constants/header-items"
import { useEffect, useRef, useState } from "react"

const GET$KNIGHT: React.FC<{}> = () => {
  const [clicked, setClicked] = useState(false)
  const listRef: React.LegacyRef<HTMLUListElement> = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (listRef.current && !listRef.current.contains(e.target as Node)) {
        setClicked(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div
      className="header_getknight_button"
      onClick={() => {
        setClicked(!clicked)
      }}
    >
      <img src={Coin} />
      <p>GET $KNIGHT</p>
      {clicked && (
        <ul className="GETKNIGHT_dropdown" ref={listRef}>
          {GET_KNIGHT_ITEMS.map((item, index) => (
            <li
              onClick={() => {
                window.open(item.href, '_blank')
              }}
              key={index}
              className="GETKNIGHT_dropdown_item"
              style={{
                borderRadius:
                  GET_KNIGHT_ITEMS.length === 1
                    ? '5px'
                    : index === GET_KNIGHT_ITEMS.length - 1
                    ? '0 0 5px 5px'
                    : index === 0
                    ? '5px 5px 0 0'
                    : '',
              }}
            >
              {item.icon && <img src={item.icon} />}
              <p
                style={{
                  left: !item.icon ? 'unset' : '',
                }}
              >
                {item.title}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default GET$KNIGHT

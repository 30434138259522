import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getDataMoralis } from 'utils/getDataMoralis'

type MergefiltersType = string[]
export type NFTData = {
  ATK: number
  DEF: number
  HP: number
  category: string
  description: string
  effectValue: string
  itemEffect: string
  generation: number
  hero: string
  image: string
  name: string
  rarity: string
  seller: string
  skillPower: string
  tokenId: string
}

interface MergeState {
  dataLoaded: boolean
  activeSession: number
  filter: MergefiltersType
  NFTs: NFTData[]
  enhanceItem: NFTData
  sacrifice1: NFTData
  sacrifice2: NFTData
  mergeData: any
  successModal: boolean
  buyKnightModal: boolean
}

const initialState: MergeState = {
  dataLoaded: false,
  activeSession: 1,
  filter: [],
  NFTs: [],
  enhanceItem: {
    tokenId: '',
    name: '',
    description: '',
    category: '',
    image: '',
    hero: '',
    itemEffect: '',
    effectValue: '',
    rarity: '',
    generation: 0,
    ATK: 0,
    DEF: 0,
    HP: 0,
    seller: '',
    skillPower: '',
  },
  sacrifice1: {
    tokenId: '',
    name: '',
    description: '',
    category: '',
    image: '',
    hero: '',
    itemEffect: '',
    effectValue: '',
    rarity: '',
    generation: 0,
    ATK: 0,
    DEF: 0,
    HP: 0,
    seller: '',
    skillPower: '',
  },
  sacrifice2: {
    tokenId: '',
    name: '',
    description: '',
    category: '',
    image: '',
    hero: '',
    itemEffect: '',
    effectValue: '',
    rarity: '',
    generation: 0,
    ATK: 0,
    DEF: 0,
    HP: 0,
    seller: '',
    skillPower: '',
  },
  mergeData: {
    tokenId: '',
    name: '',
    description: '',
    category: '',
    image: '',
    hero: '',
    itemEffect: '',
    effectValue: '',
    rarity: '',
    generation: 0,
    ATK: 0,
    DEF: 0,
    HP: 0,
    seller: '',
    skillPower: '',
  },
  successModal: false,
  buyKnightModal: false,
}

export type MergeData = {
  dataLoaded: boolean
  activeSession: number
  filter: MergefiltersType
  NFTs: NFTData[]
  enhanceItem: NFTData
  sacrifice1: NFTData
  sacrifice2: NFTData
  mergeData: any
  successModal: boolean
  buyKnightModal: boolean
}

export const MergeProgressSlice = createSlice({
  name: 'MergeProgress',
  initialState,
  reducers: {
    setUserMergeAssets: (state, { payload }: { payload: NFTData[] }) => {
      state.NFTs = payload
    },
    selectFilter: (state, { payload }: { payload: string[] }) => {
      state.filter = payload
    },

    setMergeItems: (state, { payload }: { payload: NFTData[] }) => {
      state.NFTs = payload
    },

    setDataLoaded: (state) => {
      state.dataLoaded = true
    },

    setEnhanceItem: (state, { payload }: { payload: NFTData | undefined }) => {
      if (payload) {
        if (!payload.hasOwnProperty('generation')) {
          state.enhanceItem = { ...payload, generation: 0 }
        } else state.enhanceItem = payload
      } else {
        state.enhanceItem = {
          tokenId: '',
          name: '',
          description: '',
          category: '',
          image: '',
          hero: '',
          itemEffect: '',
          effectValue: '',
          rarity: '',
          generation: 0,
          ATK: 0,
          DEF: 0,
          HP: 0,
          seller: '',
          skillPower: '',
        }
      }
    },
    setSacrifice1: (state, { payload }: { payload: NFTData | undefined }) => {
      if (payload) {
        if (!payload.hasOwnProperty('generation')) {
          state.sacrifice1 = { ...payload, generation: 0 }
        } else state.sacrifice1 = payload
      } else {
        state.sacrifice1 = {
          tokenId: '',
          name: '',
          description: '',
          category: '',
          image: '',
          hero: '',
          itemEffect: '',
          effectValue: '',
          rarity: '',
          generation: 0,
          ATK: 0,
          DEF: 0,
          HP: 0,
          seller: '',
          skillPower: '',
        }
      }
    },
    setSacrifice2: (state, { payload }: { payload: NFTData | undefined }) => {
      if (payload) {
        if (!payload.hasOwnProperty('generation')) {
          state.sacrifice2 = { ...payload, generation: 0 }
        } else state.sacrifice2 = payload
      } else {
        state.sacrifice2 = {
          tokenId: '',
          name: '',
          description: '',
          category: '',
          image: '',
          hero: '',
          itemEffect: '',
          effectValue: '',
          rarity: '',
          generation: 0,
          ATK: 0,
          DEF: 0,
          HP: 0,
          seller: '',
          skillPower: '',
        }
      }
    },

    setMergeData: (state, { payload }: { payload: any | undefined }) => {
      if (payload) {
        state.mergeData = payload
      } else {
        state.mergeData = {
          _id: '',
          tokenId: '',
          typeID: '',
          name: '',
          description: '',
          category: '',
          image: '',
          hero: '',
          itemEffect: '',
          effectValue: '',
          rarity: '',
          ATK: 0,
          DEF: 0,
          HP: 0,
          ATKIncrease: 0,
          DEFIncrease: 0,
          HPIncrease: 0,
          balance: 0,
          address: '',
          __v: 0,
        }
      }
    },

    setNextSession: (state) => {
      if (state.activeSession < 4) {
        if (state.activeSession === 1 && state.enhanceItem.tokenId !== '') {
          state.activeSession += 1
        }
        if (state.activeSession === 2 && state.sacrifice1.tokenId !== '') {
          state.activeSession += 1
        }
        if (state.activeSession === 3 && state.sacrifice2.tokenId !== '') {
          state.activeSession += 1
        }
      }
    },
    resetSession: (state) => {
      if (state.activeSession === 4) {
        state.activeSession = 1
      }
    },
    setLastSession: (state) => {
      if (state.activeSession > 1) {
        if (state.activeSession === 3) {
          state.activeSession = 2
          state.sacrifice2 = {
            tokenId: '',
            name: '',
            description: '',
            category: '',
            image: '',
            hero: '',
            itemEffect: '',
            effectValue: '',
            rarity: '',
            generation: 0,
            ATK: 0,
            DEF: 0,
            HP: 0,
            seller: '',
            skillPower: '',
          }
        } else {
          if (state.activeSession === 4) {
            state.activeSession = 3
          }
          if (state.activeSession === 2) {
            state.activeSession = 1
            state.sacrifice1 = {
              tokenId: '',
              name: '',
              description: '',
              category: '',
              image: '',
              hero: '',
              itemEffect: '',
              effectValue: '',
              rarity: '',
              generation: 0,
              ATK: 0,
              DEF: 0,
              HP: 0,
              seller: '',
              skillPower: '',
            }
          }
        }
      }
    },
    setSuccessModal: (state) => {
      state.successModal = !state.successModal
    },
    setBuyKnightModal: (state) => {
      state.buyKnightModal = !state.buyKnightModal
    },
  },
})

export const {
  setUserMergeAssets,
  selectFilter,
  setMergeItems,
  setEnhanceItem,
  setSacrifice1,
  setSacrifice2,
  setNextSession,
  setLastSession,
  setDataLoaded,
  setMergeData,
  setSuccessModal,
  setBuyKnightModal,
  resetSession,
} = MergeProgressSlice.actions
export default MergeProgressSlice.reducer

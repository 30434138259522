import { createContext, useEffect, useRef, useState } from "react";

interface chatcontext {
    userData: any;
    setUserData: React.Dispatch<any>;
}

interface props {
    children: any
}

export const userContext = createContext<chatcontext>(undefined);


export default function UserContext(props: props) {

    const [userData, setUserData] = useState<any>()

    return (
        <userContext.Provider value={{ userData, setUserData }}>
            {props.children}
        </userContext.Provider>
    )
}
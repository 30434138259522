import './Buttons.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../state'
import { setStakeModalStatus } from '../stakingSlices/ModalsSlice'
import useLockerContract from 'hooks/Thirdweb/useLockerContract'
import useKnightContract from 'hooks/Thirdweb/useKnightContract'
import { useChain, useSwitchChain } from '@thirdweb-dev/react'
import { Polygon } from '@thirdweb-dev/chains'
import { LOCKER_ADDRESS } from 'constants/index'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

export default function StakeButton({
  width,
  address,
}: {
  width?: number
  address: string
}) {
  let width2: number
  width2 = (width / 1920) * 100
  const { lock } = useLockerContract()
  const { getAllowance, approveKnight } = useKnightContract()
  const chain = useChain()
  const switchChain = useSwitchChain()
  const dispatch = useDispatch()
  const modal = useSelector((state: AppState) => state.stakingModals)
  const [allowance, setAllowance] = useState(0)
  const [Approval, setApproval] = useState<ApprovalState>(ApprovalState.PENDING)

  useEffect(() => {
    ;(async () => {
      setApproval(ApprovalState.PENDING)
      if (chain) {
        if (chain.chainId === Polygon.chainId) {
          await getAllowance(LOCKER_ADDRESS)
            .then((res) => {
              if (res === 0) {
                setApproval(ApprovalState.NOT_APPROVED)
              } else {
                setApproval(ApprovalState.APPROVED)
                setAllowance(res)
              }
            })
            .catch((err) => console.error(err))
        } else {
          setApproval(ApprovalState.UNKNOWN)
        }
      }
    })()
  }, [chain])

  return (
    <button
      onClick={async () => {
        if (Approval === ApprovalState.APPROVED) {
          if (modal.stakeModalValue > allowance) {
            setApproval(ApprovalState.PENDING)
            await approveKnight(String(modal.stakeModalValue), LOCKER_ADDRESS)
              .then(async (res) => {
                if (res !== 'error') {
                  setAllowance(modal.stakeModalValue)
                  await lock(String(modal.stakeModalValue))
                    .then((res) => {
                      dispatch(setStakeModalStatus('success'))
                    })
                    .catch((err) => {
                      console.error('lock error:', err)
                      dispatch(setStakeModalStatus('fail'))
                    })
                } else {
                  console.error('Approval error')
                  dispatch(setStakeModalStatus('fail'))
                }
              })
              .catch((err) => {
                console.error('Approval error:', err)
                dispatch(setStakeModalStatus('fail'))
              })
            setApproval(ApprovalState.APPROVED)
          } else if (modal.stakeModalValue !== 0) {
            setApproval(ApprovalState.PENDING)
            await lock(String(modal.stakeModalValue))
              .then((res) => {
                if (res !== 'error') {
                  dispatch(setStakeModalStatus('success'))
                } else {
                  console.error('lock error')
                  dispatch(setStakeModalStatus('fail'))
                }
              })
              .catch((err) => {
                console.error('lock error:', err)
                dispatch(setStakeModalStatus('fail'))
              })
          }
        } else if (Approval === ApprovalState.NOT_APPROVED) {
          if (modal.stakeModalValue !== 0) {
            setApproval(ApprovalState.PENDING)
            await approveKnight(String(modal.stakeModalValue), LOCKER_ADDRESS)
              .then(async (res) => {
                if (res !== 'error') {
                  setAllowance(modal.stakeModalValue)
                  setApproval(ApprovalState.APPROVED)
                } else {
                  console.error('Approval error')
                  setApproval(ApprovalState.NOT_APPROVED)
                }
              })
              .catch((err) => {
                console.error('Approval error:', err)
                setApproval(ApprovalState.NOT_APPROVED)
              })
          }
        } else if (Approval === ApprovalState.UNKNOWN) {
          switchChain(Polygon.chainId)
        }
      }}
      className="StakeButton"
    >
      {Approval === ApprovalState.NOT_APPROVED
        ? 'Approve $KNIGHT'
        : Approval === ApprovalState.APPROVED
        ? 'INCREASE POWER'
        : Approval === ApprovalState.PENDING
        ? 'LOADING...'
        : 'SWITCH NETWORK'}
      <span>Allowance: {allowance} $KNIGHT</span>
    </button>
  )
}

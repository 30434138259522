import { createSlice } from '@reduxjs/toolkit'

/**
 * @description Storing the redux store config for the NFT
 */

export const nftSlice = createSlice({
  name: 'nftState',
  initialState: {
    tokens: [],
    userTokens: [],
    tokensLoading: true,
    userTokensLoading: true,
    triggerReloadValue: 0,
  },
  reducers: {
    setNFTs: (state, action) => {
      state.tokens = action.payload
    },
    setUserNFTs: (state, action) => {
      state.userTokens = action.payload
    },
    setTokenLoading: (state, action) => {
      state.tokensLoading = action.payload
    },
    setUserTokenLoading: (state, action) => {
      state.userTokensLoading = action.payload
    },
    triggerNFTReload: (state) => {
      state.triggerReloadValue = state.triggerReloadValue + 1
    },
  },
})

export const {
  setNFTs,
  setUserNFTs,
  setTokenLoading,
  setUserTokenLoading,
  triggerNFTReload,
} = nftSlice.actions

export default nftSlice.reducer

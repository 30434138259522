import {
  useBids,
  useCart,
  useDynamicTokens,
  useTokenActivity,
  useTokens,
  useUserBids,
  useUserListings,
  useUserTokens,
  useUserTopBids,
} from '@reservoir0x/reservoir-kit-ui'
import axios from 'axios'
import { filtersObjectType } from 'constants/filter-items'
import { ERC1155_CONTRACT_ADDRESS, NFT_Attributes } from 'constants/index'
import { toast } from 'react-toastify'
import { NFTData, setUserForgeAssets } from 'state/slices/SpeicalMergeSlice'

export type tokenActivityType =
  | 'bid'
  | 'sale'
  | 'mint'
  | 'bid_cancel'
  | 'ask_cancel'
  | 'ask'
  | 'transfer'

export function useReservoir() {
  /**
   * @description This function returns user's assets that are not listed
   * @param address User's wallet address
   */
  const useUserNFTs = (address: string, tokenName?: string) => {
    const { data, hasNextPage, error, isLoading, fetchNextPage, mutate } =
      useUserTokens(address, {
        collection: ERC1155_CONTRACT_ADDRESS,
        limit: 200,
        includeAttributes: true,
        includeTopBid: true,
        tokenName: tokenName,
      })

    if (error)
      return {
        data: [],
        hasNextPage: false,
        isLoading: false,
        fetchNextPage: () => {},
        mutate: () => {},
      }

    const tokens = data.filter((item) => item.token.floorAsk.id === null)
    return {
      data: tokens,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
      mutate: mutate,
      fetchNextPage: fetchNextPage,
    }
  }

  /**
   * @description This function returns user's assets validated for Forge
   * @param address User's wallet address
   */
  const useForgeItems = (address: string) => {
    const obj = {
      collection: ERC1155_CONTRACT_ADDRESS,
      limit: 200,
      includeAttributes: true,
    }

    const { data, hasNextPage, error, isLoading, fetchNextPage, mutate } =
      useUserTokens(address, obj)

    if (error)
      return {
        data: [],
        hasNextPage: false,
        isLoading: false,
        fetchNextPage: () => {},
        mutate: () => {},
      }

    const tokens = data
      .filter((item) => {
        if (
          item.token.floorAsk.id === null &&
          item.token.name &&
          item.token.attributes.find((attr) => attr.key === 'Rarity').value ===
            'Special' &&
          item.token.attributes.find((attr) => attr.key === 'Category')
            .value !== 'Skin'
        ) {
          return item
        }
      })
      .map((item) => {
        // @ts-ignore
        const attributes: NFT_Attributes = {}
        item.token.attributes.forEach((attr) => {
          attributes[attr.key] = attr.value
        })
        const obj: NFTData = {
          tokenId: item.token.tokenId,
          name: item.token.name,
          category: attributes.Category,
          image: item.token.image,
          hero: attributes.heroClass,
          itemEffect: attributes.itemEffect,
          effectValue: attributes.effectValue,
          rarity: attributes.Rarity,
          generation: attributes.Prestige ? Number(attributes.Prestige) : 0,
          ATK: Number(attributes.Attack),
          DEF: Number(attributes.Defense),
          HP: Number(attributes.Health),
          seller: address,
          skillPower: attributes.skillPower || attributes.SkillPower,
        }
        return obj
      })
    return {
      data: tokens,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
      mutate: mutate,
      fetchNextPage: fetchNextPage,
    }
  }

  /**
   * @description This function returns user's assets validated for Merge
   * @param address User's wallet address
   */
  const useMergeItems = (address: string) => {
    const obj = {
      collection: ERC1155_CONTRACT_ADDRESS,
      limit: 200,
      includeAttributes: true,
    }

    const { data, hasNextPage, error, isLoading, fetchNextPage, mutate } =
      useUserTokens(address, obj)

    if (error)
      return {
        data: [],
        hasNextPage: false,
        isLoading: false,
        fetchNextPage: () => {},
        mutate: () => {},
      }

    const tokens = data
      .filter((item) => {
        if (
          item.token.floorAsk.id === null &&
          item.token.name &&
          item.token.attributes.find((attr) => attr.key === 'Rarity').value !==
            'Special' &&
          item.token.attributes.find((attr) => attr.key === 'Category')
            .value !== 'Skin'
        ) {
          return item
        }
      })
      .map((item) => {
        // @ts-ignore
        const attributes: NFT_Attributes = {}
        item.token.attributes.forEach((attr) => {
          attributes[attr.key] = attr.value
        })
        const obj: NFTData = {
          tokenId: item.token.tokenId,
          name: item.token.name,
          category: attributes.Category,
          image: item.token.image,
          hero: attributes.heroClass,
          itemEffect: attributes.itemEffect,
          effectValue: attributes.effectValue,
          rarity: attributes.Rarity,
          generation: attributes.Prestige ? Number(attributes.Prestige) : 0,
          ATK: Number(attributes.Attack),
          DEF: Number(attributes.Defense),
          HP: Number(attributes.Health),
          seller: address,
          skillPower: attributes.skillPower || attributes.SkillPower,
        }
        return obj
      })
    return {
      data: tokens,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
      mutate: mutate,
      fetchNextPage: fetchNextPage,
    }
  }

  /**
   * @description This hook returns user's active listings
   */
  const useActiveListings = (address: string, tokenName?: string) => {
    const { data, hasNextPage, fetchNextPage, error, isLoading, mutate } =
      useUserTokens(address, {
        collection: ERC1155_CONTRACT_ADDRESS,
        limit: 200,
        includeAttributes: true,
        onlyListed: true,
        includeTopBid: true,
        tokenName: tokenName,
      })

    if (error)
      return {
        data: [],
        fetchNextPage: () => {},
        hasNextPage: false,
        isLoading: false,
        mutate: () => {},
      }

    return {
      data: data,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
      mutate: mutate,
    }
  }

  /**
   * @description This hook returns user's sold listings
   */
  const useSoldListings = (address: string) => {
    const { data, fetchNextPage, hasNextPage, error, isLoading } =
      useUserListings(address, {
        status: 'filled',
        includeCriteriaMetadata: true,
        limit: 10,
        includeRawData: true,
        collection: ERC1155_CONTRACT_ADDRESS,
      })
    if (error)
      return {
        data: [],
        fetchNextPage: () => {},
        hasNextPage: false,
        isLoading: false,
      }

    return {
      data: data,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
    }
  }

  /**
   * @description This hook returns user's expired listings
   */
  const useUnsoldListings = (address: string) => {
    const { data, fetchNextPage, hasNextPage, error, isLoading } =
      useUserListings(address, {
        status: 'expired',
        includeCriteriaMetadata: true,
        limit: 10,
        collection: ERC1155_CONTRACT_ADDRESS,
      })
    if (error)
      return {
        data: [],
        fetchNextPage: () => {},
        hasNextPage: false,
        isLoading: false,
      }

    return {
      data: data,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
    }
  }

  /**
   * @description This function returns an object including user's offers
   * @param address User's wallet
   */
  const useUserBidsList = (address: string) => {
    const { data, hasNextPage, fetchNextPage, error, mutate, isFetchingPage } =
      useUserBids(address as `0x${string}`, {
        collection: ERC1155_CONTRACT_ADDRESS,
        limit: 10,
        includeCriteriaMetadata: true,
        type: 'token',
      })

    if (error)
      return {
        data: [],
        fetchNextPage: () => {},
        hasNextPage: false,
        isLoading: false,
        mutate: () => {},
      }

    return {
      data: data,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      mutate: mutate,
      isLoading: isFetchingPage,
    }
  }

  /**
   * @description This function returns an object including offers on user assets
   * @param address User's wallet
   */
  const useUserOffers = (address: string) => {
    const { data, hasNextPage, fetchNextPage, error, mutate, isFetchingPage } =
      useUserTopBids(address, {
        collection: ERC1155_CONTRACT_ADDRESS,
        limit: 10,
        includeCriteriaMetadata: true,
      })

    if (error)
      return {
        data: [],
        fetchNextPage: () => {},
        hasNextPage: false,
        isLoading: false,
        mutate: () => {},
      }

    return {
      data: data,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      mutate: mutate,
      isLoading: isFetchingPage,
    }
  }

  /**
   * @description This hook returns all NFTs in collection
   */
  const useGetAllNfts = ({
    sort,
    filtersOption,
    tokenName,
  }: {
    filtersOption: filtersObjectType
    sort: {
      value: 'floorAskPrice' | 'tokenId' | 'rarity' | 'updatedAt' | 'listedAt'
      direction: 'asc' | 'desc'
    }
    tokenName?: string
  }) => {
    const currencies = [
      filtersOption.Currency.KNIGHT,
      filtersOption.Currency.MATIC,
      filtersOption.Currency.WETH,
    ]
      .filter((item) => item.active === true)
      .map((item) => item.option)

    const obj = {
      collection: ERC1155_CONTRACT_ADDRESS,
      includeAttributes: true,
      limit: 20,
      sortBy: sort.value,
      sortDirection: sort.direction,
      currencies: currencies,
      excludeBurnt: true,
      includeTopBid: true,
      includeLastSale: true,
      tokenName: tokenName,
      minFloorAskPrice: filtersOption.Price.min,
      maxFloorAskPrice: filtersOption.Price.max,
    }
    for (const key in filtersOption) {
      if (key === 'Currency' || key === 'Price') continue
      if (key === 'SkillPower') {
        obj[`attributes[${key}]`] = filtersOption[key]
        continue
      }
      for (const key1 in filtersOption[key]) {
        if (filtersOption[key][key1].active) {
          if (obj[`attributes[${key}]`]) {
            obj[`attributes[${key}]`] = [
              ...obj[`attributes[${key}]`],
              `${filtersOption[key][key1].option}`,
            ]
          } else {
            obj[`attributes[${key}]`] = [`${filtersOption[key][key1].option}`]
          }
        }
      }
    }
    const {
      data,
      fetchNextPage,
      hasNextPage,
      isLoading,
      error,
      isFetchingInitialData,
      isFetchingPage,
      mutate,
      add,
      remove,
    } = useDynamicTokens(obj)

    if (error)
      return {
        data: [],
        hasNextPage: false,
        isLoading: false,
        isFetchingInitialData: false,
        isFetchingPage: false,
        fetchNextPage: () => {},
        mutate: () => {},
        addToCart: () => {},
        removeFromCart: () => {},
      }

    return {
      data: data,
      hasNextPage: hasNextPage,
      isLoading: isLoading,
      isFetchingInitialData,
      isFetchingPage,
      mutate: mutate,
      fetchNextPage: fetchNextPage,
      addToCart: add,
      removeFromCart: remove,
    }
  }

  /**
   * @description This function returns an object including NFT details
   * @param tokenId Token id of the NFT
   */
  const useTokenData = (tokenId: string) => {
    const { data, error, mutate } = useTokens({
      tokens: [`${ERC1155_CONTRACT_ADDRESS}:${tokenId}`],
      includeTopBid: true,
    })

    if (error || data.length === 0)
      return {
        data: undefined,
        mutate: () => {},
      }

    return {
      data: data[0],
      mutate: mutate,
    }
  }

  /**
   * @description This function returns an object including NFT attributes
   * @param tokenId Token id of the NFT
   */
  const getTokenAttributes = async (tokenId: string) => {
    const options = {
      method: 'GET',
      url: `https://api-polygon.reservoir.tools/collections/${ERC1155_CONTRACT_ADDRESS}/attributes/explore/v5?tokenId=${tokenId}`,
      headers: {
        accept: '*/*',
        'x-api-key': process.env.REACT_APP_RESERVOIR_KEY,
      },
    }

    const response = await axios
      .request(options)
      .then(function (response) {
        if (response.status === 200) {
          return response.data.attributes
        } else {
          return []
        }
      })
      .catch(function (error) {
        console.error(error)
        return []
      })

    if (response.length === 0) return undefined
    const attributes = {}
    response.forEach((item) => {
      attributes[item.key] = item.value
    })
    return attributes as NFT_Attributes
  }

  /**
   * @description This function returns an array including NFT activities
   * @param tokenId Token id of the NFT
   */
  const useTokenActivities = (tokenId: string) => {
    const {
      data: activity,
      hasNextPage,
      fetchNextPage,
      isFetchingPage,
      mutate,
      error,
    } = useTokenActivity(`${ERC1155_CONTRACT_ADDRESS}:${tokenId}`, {
      limit: 20,
      sortBy: 'eventTimestamp',
    })

    if (error)
      return {
        data: [],
        hasNextPage: false,
        fetchNextPage: () => {},
        mutate: () => {},
        isLoading: false,
      }

    return {
      data: activity,
      hasNextPage: hasNextPage,
      isLoading: isFetchingPage,
      fetchNextPage,
      mutate,
    }
  }

  /**
   * @description This function returns an object including NFT offers
   * @param tokenId Token id of the NFT
   */
  const useTokenBids = (tokenId: string) => {
    const { data, hasNextPage, fetchNextPage, error, mutate, isFetchingPage } =
      useBids({
        token: `${ERC1155_CONTRACT_ADDRESS}:${tokenId}`,
        sortBy: 'price',
        sortDirection: 'desc',
        limit: 20,
        sources: ['hub.forestknight.io'],
      })

    if (error)
      return {
        data: [],
        fetchNextPage: () => {},
        hasNextPage: false,
        isLoading: false,
        mutate: () => {},
      }

    return {
      data: data,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      mutate: mutate,
      isLoading: isFetchingPage,
    }
  }

  /**
   * @description This function returns an object including Cart interaction functions and data
   */
  const useCartHooks = () => {
    const { data, clear, clearTransaction, validate, remove, add, checkout } =
      useCart((cart) => cart)

    return {
      data,
      clear,
      clearTransaction,
      validate,
      remove,
      add,
      checkout,
    }
  }

  /**
   * @description This function handles updating a single token metadata
   * @param tokenId Token id of the NFT
   */
  const refreshMetaData = async (
    tokenId: string,
    refreshStats?: () => void,
  ) => {
    const raw = JSON.stringify({
      liquidityOnly: false,
      overrideCoolDown: false,
      tokens: [`${ERC1155_CONTRACT_ADDRESS}:${tokenId}`],
    })
    await axios
      .post(`https://api-polygon.reservoir.tools/tokens/refresh/v2`, raw, {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_RESERVOIR_KEY,
        },
      })
      .then((res) => {
        if (res.data.results[0].isError) {
          toast.error("Token's metadata has been updated recently")
        } else {
          toast.success('Metadata updated')
          if (refreshStats) refreshStats()
        }
      })
      .catch((err) => {
        toast.error('An error happened')
        console.error(err)
      })
  }

  return {
    useUserNFTs,
    useActiveListings,
    useSoldListings,
    useUnsoldListings,
    useUserOffers,
    useTokenData,
    getTokenAttributes,
    useTokenActivities,
    useGetAllNfts,
    useTokenBids,
    useUserBidsList,
    useCartHooks,
    refreshMetaData,
    useForgeItems,
    useMergeItems,
  }
}

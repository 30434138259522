import '../../../styles/common/Header.scss'
import { navArray } from '../../../constants/header-items'
import { ForestKnight } from '../../../assets/index'
import React, { SetStateAction, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import NavButton from './NavButton'
import DropDown from './Dropdown'
import GET$KNIGHT from './GET$KNIGHT'
import ConnectButton from './ConnectButton'
import Cart from 'components/Reservoir/Cart'

export default function Header() {
  const history = useHistory()
  const location = useLocation()
  const [openDropdown, setOpenDropdown] = useState(false)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [location.pathname])

  return (
    <header className="header_container">
      <>
        <BurgerIcon clicked={openDropdown} setClicked={setOpenDropdown} />
        <ConnectButton className="mobile_connect_button" />
        <div className="header_cart">
          <Cart />
        </div>
        {openDropdown && (
          <DropDown
            history={history}
            pathname={location.pathname}
            setClicked={setOpenDropdown}
          />
        )}
      </>
      <div className="header_content_wrapper">
        <div className="site_logo">
          <img src={ForestKnight} alt="Forestknight" />
        </div>
        <ul className="header_buttons_box">
          {navArray.map((item, index) => (
            <NavButton
              navItem={item}
              history={history}
              key={item.label}
              pathname={location.pathname}
            />
          ))}
        </ul>
        <div className="header_connect_box">
          <Cart />
          <ConnectButton />
          <GET$KNIGHT />
        </div>
      </div>
    </header>
  )
}

const BurgerIcon: React.FC<{
  clicked: boolean
  setClicked: React.Dispatch<SetStateAction<boolean>>
}> = ({ clicked, setClicked }) => {
  return (
    <div className="burger_container" onClick={() => setClicked(!clicked)}>
      {clicked ? (
        <>
          <span style={{ transform: 'rotate(45deg)', position: 'absolute' }} />
          <span style={{ transform: 'rotate(-45deg)', position: 'absolute' }} />
        </>
      ) : (
        <>
          <span />
          <span />
          <span />
        </>
      )}
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export const voteDetailSlice = createSlice({
  name: 'voteDetail',
  initialState: {
    pageId: 0,
  },
  reducers: {
    setPageId: (state, { payload }: { payload: number }) => {
      state.pageId = payload
    },
  },
})

export const { setPageId } = voteDetailSlice.actions

export default voteDetailSlice.reducer

import Routes, { Loading } from './Router'
import AllModals from './pages/AllModals'
import StakingModals from './components/Staking/modalComponents/StakingModals'
import ChestBurnModals from './components/Staking/modalComponents/ChestBurnModal'
import {
  CartProvider,
  ReservoirKitProvider,
  darkTheme,
} from '@reservoir0x/reservoir-kit-ui'
import { reservoirChains } from '@reservoir0x/reservoir-sdk'
import { WagmiProvider } from 'wagmi'
import { useEffect } from 'react'
import { useConnectionStatus } from '@thirdweb-dev/react'
import { thirdWebWallet } from 'config'
import { useDispatch } from 'react-redux'
import { getMaticPrice } from 'state/application/actions'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useSDK } from '@thirdweb-dev/react'
import { createConfig, http } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { coinbaseWallet, walletConnect } from 'wagmi/connectors'
import { useEthersAdapter } from 'hooks/Reservoir/useEthersAdapter'

const coinbaseWalletInstance = coinbaseWallet()
const walletConnectInstance = walletConnect({
  projectId: process.env.REACT_APP_PROJECT_ID,
})

const theme = darkTheme({
  headlineFont: 'Roboto',
  font: 'Roboto',
  primaryColor: '#323aa8',
  primaryHoverColor: '#252ea5',
  contentBackground: '#211c32',
  headerBackground: '#211c32',
  borderColor: '#1e9f5f',
})

const queryCLient = new QueryClient()

export default function App() {
  const dispatch = useDispatch()
  const connection = useConnectionStatus()
  const sdk = useSDK()
  const wallet = useEthersAdapter()

  useEffect(() => {
    const fetchMaticPrice = async () => {
      await fetch(
        'https://api.diadata.org/v1/assetQuotation/Polygon/0x0000000000000000000000000000000000001010',
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch(getMaticPrice({ price: Number(res.Price.toFixed(3)) }))
        })
        .catch((err) => {
          console.error(err)
          dispatch(getMaticPrice({ price: 1 }))
        })
    }
    fetchMaticPrice()
  }, [])

  // @ts-ignore
  const config = createConfig({
    chains: [polygon],
    transports: {
      [polygon.id]: http(),
    },
    syncConnectedChain: true,
    connectors: [
      thirdWebWallet({
        sdk: sdk,
        shimDisconnect: true,
      }),
      coinbaseWalletInstance,
      // walletConnectInstance,
    ],
  })

  if (connection === 'connected' || connection === 'disconnected') {
    return (
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryCLient}>
          <ReservoirKitProvider
            options={{
              apiKey: process.env.REACT_APP_RESERVOIR_KEY,
              chains: [{ ...reservoirChains.polygon, active: true }],
              source: 'hub.forestknight.io',
              normalizeRoyalties: true,
              disablePoweredByReservoir: true,
              coinGecko: {
                coinIds: {
                  knight: 'forest-knight',
                },
              },
            }}
            theme={theme}
          >
            {/* @ts-ignore */}
            <CartProvider persist walletClient={wallet}>
              <StakingModals />
              <ChestBurnModals />
              <AllModals />
              <Routes />
            </CartProvider>
          </ReservoirKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    )
  } else return <Loading />
}

import { createAction } from '@reduxjs/toolkit'

export type PopupContent =
  | {
      txn: {
        hash: string
        success: boolean
        summary?: string
      }
    }
  | {
      listUpdate: {
        listUrl: string
        auto: boolean
      }
    }

export enum ApplicationModal {
  WALLET,
  SETTINGS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  DELEGATE,
  VOTE,
  LANGUAGE,
  FAUCET,
  STAKE,
}

export const updateBlockNumber = createAction<{
  chainId: number
  blockNumber: number
}>('application/updateBlockNumber')
export const setOpenModal = createAction<ApplicationModal | null>(
  'application/setOpenModal',
)
export const addPopup = createAction<{
  key?: string
  removeAfterMs?: number | null
  content: PopupContent
}>('application/addPopup')
export const removePopup = createAction<{ key: string }>(
  'application/removePopup',
)
export const setKashiApprovalPending = createAction<string>(
  'application/setKashiApprovalPending',
)
export const setKnightPrice = createAction<any>('application/setKnightPrice')

export const setKnightBalance = createAction<any>(
  'application/setKnightBalance',
)
export const setProposals = createAction<any>('application/setProposals')
export const setCurrentBlockTimestamp = createAction<any>(
  'application/setCurrentBlockTimestamp',
)

export const getMaticPrice = createAction<{ price: number }>(
  'application/getMaticPrice',
)

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getDataMoralis } from 'utils/getDataMoralis'
import axios from 'utils/axios'

export type NFTData = {
  ATK: number
  DEF: number
  HP: number
  category: string
  effectValue: string
  itemEffect: string
  generation: number
  hero: string
  image: string
  name: string
  rarity: string
  seller: string
  skillPower: string
  tokenId: string
}

export type SpecialMergeResult = {
  _id: string
  tokenId: string
  typeID: string
  name: string
  description: string
  category: string
  image: string
  hero: string
  itemEffect: string
  generation: number
  effectValue: string
  rarity: string
  ATK: number
  DEF: number
  HP: number
  ATKIncrease: number
  DEFIncrease: number
  HPIncrease: number
  balance: number
  address: string
  __v: number
  skillPower: any
}

export interface SpecialMergeData {
  dataLoaded: boolean
  activeSession: number
  userAssets: NFTData[]
  enhanceItem: NFTData | undefined
  sacrifice1: NFTData | undefined
  sacrifice2: NFTData | undefined
  sacrifice3: NFTData | undefined
  mergeResult: SpecialMergeResult | undefined
  successModal: boolean
  buyKnightModal: boolean
  estimating: boolean
}

const initialState: SpecialMergeData = {
  dataLoaded: false,
  activeSession: 1,
  userAssets: [],
  enhanceItem: undefined,
  sacrifice1: undefined,
  sacrifice2: undefined,
  sacrifice3: undefined,
  mergeResult: undefined,
  successModal: false,
  buyKnightModal: false,
  estimating: true,
}

export const estimateMergeResult = createAsyncThunk(
  'SpecialMerge/estimateMerge',
  async ({
    mainNFT,
    components,
  }: {
    mainNFT: string
    components: string[]
  }) => {
    const data = await axios.post(
      '/estimateMetadata',
      {
        mainNFT: mainNFT,
        components: components,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'content-type': 'application/json',
        },
      },
    )
    return data as any
  },
)

export const SpecialMergeSlice = createSlice({
  name: 'SpecialMerge',
  initialState,
  reducers: {
    setUserForgeAssets: (state, { payload }: { payload: NFTData[] }) => {
      state.userAssets = payload
    },
    // Handles user asset selection for merging
    handleMergeItemSelection: (
      state,
      {
        payload,
      }: { payload: { data: NFTData | undefined; session: 1 | 2 | 3 | 4 } },
    ) => {
      const selectData = payload.data
        ? !payload.data.hasOwnProperty('generation')
          ? { ...payload.data, generation: 0 }
          : payload.data
        : undefined

      switch (payload.session) {
        case 1:
          state.enhanceItem = selectData
          break
        case 2:
          state.sacrifice1 = selectData
          break
        case 3:
          state.sacrifice2 = selectData
          break
        case 4:
          state.sacrifice3 = selectData
          break
      }
    },

    // Forward user to the Next stage of merge
    setNextSession: (state) => {
      switch (state.activeSession) {
        case 1:
          {
            if (state.enhanceItem) state.activeSession += 1
          }
          break
        case 2:
          {
            if (state.sacrifice1) state.activeSession += 1
          }
          break
        case 3:
          {
            if (state.sacrifice2) state.activeSession += 1
          }
          break
        case 4: {
          if (state.sacrifice3) state.activeSession += 1
        }
      }
    },

    // Rollbacks user to the previous stage of merge
    setLastSession: (state) => {
      switch (state.activeSession) {
        case 1:
          break
        case 2:
          {
            state.activeSession -= 1
            state.sacrifice1 = undefined
          }
          break
        case 3:
          {
            state.activeSession -= 1
            state.sacrifice2 = undefined
          }
          break
        case 4:
          {
            state.activeSession -= 1
            state.sacrifice3 = undefined
          }
          break
        case 5: {
          state.activeSession -= 1
        }
      }
    },

    // Resets all the merge proccess
    resetSession: (state) => {
      state.activeSession = 1
      state.enhanceItem = undefined
      state.sacrifice1 = undefined
      state.sacrifice2 = undefined
      state.sacrifice3 = undefined
    },

    // Opens Success modal
    setSuccessModal: (state) => {
      state.successModal = !state.successModal
    },

    // Opens buy knight modal
    setBuyKnightModal: (state) => {
      state.buyKnightModal = !state.buyKnightModal
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(estimateMergeResult.fulfilled, (state, action) => {
        state.mergeResult = action.payload.data.nft
        state.estimating = false
      })
      .addCase(estimateMergeResult.rejected, (state, action) => {
        state.mergeResult = undefined
        state.estimating = false
      })
      .addCase(estimateMergeResult.pending, (state) => {
        state.estimating = true
      })
  },
})

export const {
  setUserForgeAssets,
  handleMergeItemSelection,
  setNextSession,
  setLastSession,
  setSuccessModal,
  setBuyKnightModal,
  resetSession,
} = SpecialMergeSlice.actions
export default SpecialMergeSlice.reducer

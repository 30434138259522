import { useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { BsStars } from 'react-icons/bs'
import { MdOutlineIosShare } from 'react-icons/md'
import { MdClose } from 'react-icons/md'

const steps = [
  {
    text: (
      <p
        style={{
          color: '#8e6bb1',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        <span
          style={{
            marginRight: 2,
          }}
        >
          <BsStars color="#400076" />
        </span>
        In the browser menu, tap
        <span
          style={{
            fontWeight: 600,
            color: '#400076',
            marginLeft: 5,
          }}
        >
          Share icon <MdOutlineIosShare />
        </span>
      </p>
    ),
  },
  {
    text: (
      <p
        style={{
          color: '#8e6bb1',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        <span
          style={{
            marginRight: 2,
          }}
        >
          <BsStars color="#400076" />
        </span>
        Choose{' '}
        <span
          style={{
            fontWeight: 600,
            color: '#400076',
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          Add to Home Screen
        </span>
        in the options. Then click{' '}
        <span
          style={{
            fontWeight: 600,
            color: '#400076',
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          Add
        </span>
      </p>
    ),
  },
  {
    text: (
      <p
        style={{
          color: '#8e6bb1',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        <span
          style={{
            marginRight: 2,
          }}
        >
          <BsStars color="#400076" />
        </span>{' '}
        Open the MarketPlace app from your Home screen.
      </p>
    ),
  },
]

export default function InstallPopUp({
  close,
  os,
}: {
  close: () => void
  os: 'iOS' | 'Desktop' | 'Windows Phone' | 'Android'
}) {
  const [deferredPrompt, setDeferredPrompt] = useState<Event>()
  const [openModal, setOpenModal] = useState(false)

  window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault()
    setDeferredPrompt(e)
  })

  return (
    <div
      style={{
        height: 60,
        width: '100vw',
        backgroundColor: 'rgba(49,28,49,0.9)',
        position: 'fixed',
        zIndex: 60,
        top: 90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <IoCloseSharp
        style={{
          height: 50,
          width: 50,
          color: '#56525D',
          marginLeft: 5,
        }}
        onClick={close}
      />
      <img src="apk_icon_192x192.png" style={{ height: 40, width: 40 }} />
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 7,
        }}
      >
        <h4 style={{ color: 'white', fontSize: 16 }}>
          Forest Knight MarketPlace
        </h4>
        <p style={{ color: 'rgb(155,155,155)', fontSize: 14 }}>
          Install MarketPlace App
        </p>
      </div>
      <button
        onClick={() => {
          if (os === 'Android') {
            if (deferredPrompt) {
              // @ts-ignore
              deferredPrompt.prompt()
              // Wait for the user to respond to the prompt
              // @ts-ignore
              deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('User accepted the install prompt')
                } else {
                  console.log('User dismissed the install prompt')
                }
              })
            }
          } else {
            setOpenModal(true)
          }
        }}
        style={{
          height: 35,
          width: 120,
          backgroundColor: '#fc72ff',
          color: 'white',
          borderRadius: 50,
          fontSize: 16,
          fontWeight: 500,
          marginRight: 15,
        }}
      >
        {os === 'Android' ? 'Install' : 'Add'}
      </button>
      {openModal && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            background: 'rgba(24,8,34,0.7)',
            position: 'fixed',
            zIndex: 65,
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            setOpenModal(false)
          }}
        >
          <div
            style={{
              width: '330px',
              backgroundColor: 'white',
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 20,
              position: 'relative',
              top: 15,
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <MdClose
              style={{
                height: 35,
                width: 35,
                position: 'absolute',
                top: '2%',
                right: '3%',
                color: '#400076',
              }}
              onClick={() => {
                setOpenModal(false)
              }}
            />
            <img
              src={'/iosConnect.png'}
              style={{
                width: '90%',
              }}
            />
            <h2
              style={{
                color: '#400076',
                fontSize: 24,
                fontFamily: 'Roboto',
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Add MarketPlace to <br />
              your Home screen
            </h2>
            <div
              style={{
                width: '84%',
                display: 'flex',
                flexDirection: 'column',
                gap: 15,
                paddingBottom: 20,
              }}
            >
              {steps.map((item, index) => (
                <>{item.text}</>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

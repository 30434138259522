import { ChainId, Token } from '@sushiswap/sdk'
import Web3 from 'web3'
import {
  Monster,
  Arcanist,
  ArcherIcon,
  Witch,
  torso,
  Thief,
  SamuraiIcon,
  RaiderIcon,
  PriestIcon,
  Paladin,
  Ninja,
  MageIcon,
  InfernalSword,
  Golden,
  Coin,
  Equip,
  Barbarian,
  AssassinIcon,
  Vector,
  warrior,
  Livia,
  Wukong,
  SpartanIcon,
  ShamanIcon,
  All,
  MATIC,
  WETH,
  WMATIC,
} from '../assets/index'

type ChainTokenMap = {
  readonly [chainId in ChainId]?: Token
}

export type tokenType = {
  isInCart?: boolean
  token?:
    | {
        chainId: number
        contract: string
        tokenId: string
        name?: string | undefined
        description?: string | undefined
        image?: string | undefined
        imageSmall?: string | undefined
        imageLarge?: string | undefined
        metadata?:
          | {
              [key: string]: unknown
            }
          | undefined
        media?: string | undefined
        kind?: string | undefined
        isFlagged?: boolean | undefined
        isSpam?: boolean | undefined
        isNsfw?: boolean | undefined
        metadataDisabled?: boolean | undefined
        lastFlagUpdate?: string | undefined
        lastFlagChange?: string | undefined
        supply?: number | undefined
        remainingSupply?: number | undefined
        rarity?: number | undefined
        rarityRank?: number | undefined
        collection?:
          | {
              id?: string | undefined
              name?: string | undefined
              image?: string | undefined
              slug?: string | undefined
              symbol?: string | undefined
              creator?: string | undefined
              tokenCount?: number | undefined
              metadataDisabled?: boolean | undefined
              floorAskPrice?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | undefined
        lastSale?:
          | {
              id?: string | undefined
              saleId?: string | undefined
              token?:
                | {
                    contract?: string | undefined
                    tokenId?: string | undefined
                    name?: string | undefined
                    image?: string | undefined
                    collection?:
                      | {
                          id?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
              orderSource?: string | undefined
              orderSide?: 'ask' | 'bid' | undefined
              orderKind?: string | undefined
              orderId?: string | undefined
              orderIsReservoir?: boolean | undefined
              from?: string | undefined
              to?: string | undefined
              amount?: string | undefined
              fillSource?: string | undefined
              block?: number | undefined
              txHash?: string | undefined
              logIndex?: number | undefined
              batchIndex?: number | undefined
              timestamp?: number | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              washTradingScore?: number | undefined
              royaltyFeeBps?: number | undefined
              marketplaceFeeBps?: number | undefined
              paidFullRoyalty?: boolean | undefined
              feeBreakdown?:
                | {
                    kind?: string | undefined
                    bps?: number | undefined
                    recipient?: string | undefined
                    source?: string | undefined
                    rawAmount?: string | undefined
                  }[]
                | undefined
              comment?: string | undefined
              isDeleted?: boolean | undefined
              createdAt?: string | undefined
              updatedAt?: string | undefined
            }
          | undefined
        owner?: string | undefined
        attributes?:
          | {
              key?: string | undefined
              kind?: string | undefined
              value: string
              tokenCount?: number | undefined
              onSaleCount?: number | undefined
              floorAskPrice?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              topBidValue?: number | undefined
              createdAt?: string | undefined
            }[]
          | undefined
        decimals?: number | undefined
        mintStages?:
          | {
              stage: string
              tokenId?: string | undefined
              kind: string
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              startTime?: number | undefined
              endTime?: number | undefined
              maxMintsPerWallet?: number | undefined
            }[]
          | undefined
      }
    | undefined
  market?:
    | {
        floorAsk?:
          | {
              id?: string | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              maker?: string | undefined
              validFrom?: number | undefined
              validUntil?: number | undefined
              quantityFilled?: number | undefined
              quantityRemaining?: number | undefined
              dynamicPricing?:
                | {
                    kind?: 'dutch' | 'pool' | undefined
                    data?:
                      | {
                          [key: string]: unknown
                        }
                      | undefined
                  }
                | undefined
              source?:
                | {
                    id?: string | undefined
                    domain?: string | undefined
                    name?: string | undefined
                    icon?: string | undefined
                    url?: string | undefined
                  }
                | undefined
            }
          | undefined
        topBid?:
          | {
              id?: string | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              maker?: string | undefined
              validFrom?: number | undefined
              validUntil?: number | undefined
              source?:
                | {
                    id?: string | undefined
                    domain?: string | undefined
                    name?: string | undefined
                    icon?: string | undefined
                    url?: string | undefined
                  }
                | undefined
              feeBreakdown?:
                | {
                    kind?: string | undefined
                    recipient?: string | undefined
                    bps?: number | undefined
                  }[]
                | undefined
            }
          | undefined
      }
    | undefined
  updatedAt?: string | undefined
}

export type userTokenType = {
  token?:
    | {
        chainId: number
        contract?: string | undefined
        tokenId?: string | undefined
        kind?: string | undefined
        name?: string | undefined
        image?: string | undefined
        imageSmall?: string | undefined
        imageLarge?: string | undefined
        metadata?:
          | {
              [key: string]: unknown
            }
          | undefined
        description?: string | undefined
        supply?: number | undefined
        remainingSupply?: number | undefined
        rarityScore?: number | undefined
        rarityRank?: number | undefined
        media?: string | undefined
        isFlagged?: boolean | undefined
        isSpam?: boolean | undefined
        isNsfw?: boolean | undefined
        metadataDisabled?: boolean | undefined
        lastFlagUpdate?: string | undefined
        lastFlagChange?: string | undefined
        collection?:
          | {
              id?: string | undefined
              name?: string | undefined
              slug?: string | undefined
              symbol?: string | undefined
              contractDeployedAt?: string | undefined
              imageUrl?: string | undefined
              isSpam?: boolean | undefined
              isNsfw?: boolean | undefined
              metadataDisabled?: boolean | undefined
              openseaVerificationStatus?: string | undefined
              tokenCount?: string | undefined
              floorAsk?:
                | {
                    id?: string | undefined
                    price?:
                      | {
                          currency?:
                            | {
                                contract?: string | undefined
                                name?: string | undefined
                                symbol?: string | undefined
                                decimals?: number | undefined
                                chainId?: number | undefined
                              }
                            | undefined
                          amount?:
                            | {
                                raw?: string | undefined
                                decimal?: number | undefined
                                usd?: number | undefined
                                native?: number | undefined
                              }
                            | undefined
                          netAmount?:
                            | {
                                raw?: string | undefined
                                decimal?: number | undefined
                                usd?: number | undefined
                                native?: number | undefined
                              }
                            | undefined
                        }
                      | undefined
                    maker?: string | undefined
                    validFrom?: number | undefined
                    validUntil?: number | undefined
                    source?:
                      | {
                          id?: string | undefined
                          domain?: string | undefined
                          name?: string | undefined
                          icon?: string | undefined
                          url?: string | undefined
                        }
                      | undefined
                  }
                | undefined
              royaltiesBps?: number | undefined
              royalties?:
                | {
                    bps?: number | undefined
                    recipient?: string | undefined
                  }[]
                | undefined
            }
          | undefined
        lastSale?:
          | {
              id?: string | undefined
              saleId?: string | undefined
              token?:
                | {
                    contract?: string | undefined
                    tokenId?: string | undefined
                    name?: string | undefined
                    image?: string | undefined
                    collection?:
                      | {
                          id?: string | undefined
                          name?: string | undefined
                        }
                      | undefined
                  }
                | undefined
              orderSource?: string | undefined
              orderSide?: 'ask' | 'bid' | undefined
              orderKind?: string | undefined
              orderId?: string | undefined
              orderIsReservoir?: boolean | undefined
              from?: string | undefined
              to?: string | undefined
              amount?: string | undefined
              fillSource?: string | undefined
              block?: number | undefined
              txHash?: string | undefined
              logIndex?: number | undefined
              batchIndex?: number | undefined
              timestamp?: number | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              washTradingScore?: number | undefined
              royaltyFeeBps?: number | undefined
              marketplaceFeeBps?: number | undefined
              paidFullRoyalty?: boolean | undefined
              feeBreakdown?:
                | {
                    kind?: string | undefined
                    bps?: number | undefined
                    recipient?: string | undefined
                    source?: string | undefined
                    rawAmount?: string | undefined
                  }[]
                | undefined
              comment?: string | undefined
              isDeleted?: boolean | undefined
              createdAt?: string | undefined
              updatedAt?: string | undefined
            }
          | undefined
        topBid?:
          | {
              id?: string | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              source?:
                | {
                    id?: string | undefined
                    domain?: string | undefined
                    name?: string | undefined
                    icon?: string | undefined
                    url?: string | undefined
                  }
                | undefined
            }
          | undefined
        floorAsk?:
          | {
              id?: string | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              maker?: string | undefined
              validFrom?: number | undefined
              validUntil?: number | undefined
              quantityFilled?: number | undefined
              quantityRemaining?: number | undefined
              dynamicPricing?:
                | {
                    kind?: 'dutch' | 'pool' | undefined
                    data?:
                      | {
                          [key: string]: unknown
                        }
                      | undefined
                  }
                | undefined
              source?:
                | {
                    id?: string | undefined
                    domain?: string | undefined
                    name?: string | undefined
                    icon?: string | undefined
                    url?: string | undefined
                  }
                | undefined
            }
          | undefined
        lastAppraisalValue?: number | undefined
        attributes?:
          | {
              key?: string | undefined
              kind?: string | undefined
              value: string
              tokenCount?: number | undefined
              onSaleCount?: number | undefined
              floorAskPrice?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              topBidValue?: number | undefined
              createdAt?: string | undefined
            }[]
          | undefined
      }
    | undefined
  ownership?:
    | {
        tokenCount?: string | undefined
        onSaleCount?: string | undefined
        floorAsk?:
          | {
              id?: string | undefined
              price?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
              maker?: string | undefined
              kind?: string | undefined
              validFrom?: number | undefined
              validUntil?: number | undefined
              source?:
                | {
                    id?: string | undefined
                    domain?: string | undefined
                    name?: string | undefined
                    icon?: string | undefined
                    url?: string | undefined
                  }
                | undefined
              rawData?:
                | {
                    [key: string]: unknown
                  }
                | undefined
              isNativeOffChainCancellable?: boolean | undefined
            }
          | undefined
        acquiredAt?: string | undefined
      }
    | undefined
}

export type bidType = {
  id: string
  kind: string
  side: 'buy' | 'sell'
  status?: string | undefined
  tokenSetId: string
  tokenSetSchemaHash: string
  contract?: string | undefined
  contractKind?: string | undefined
  maker: string
  taker: string
  price?:
    | {
        currency?:
          | {
              contract?: string | undefined
              name?: string | undefined
              symbol?: string | undefined
              decimals?: number | undefined
              chainId?: number | undefined
            }
          | undefined
        amount?:
          | {
              raw?: string | undefined
              decimal?: number | undefined
              usd?: number | undefined
              native?: number | undefined
            }
          | undefined
        netAmount?:
          | {
              raw?: string | undefined
              decimal?: number | undefined
              usd?: number | undefined
              native?: number | undefined
            }
          | undefined
      }
    | undefined
  validFrom: number
  validUntil: number
  quantityFilled?: number | undefined
  quantityRemaining?: number | undefined
  dynamicPricing?:
    | {
        kind?: 'dutch' | undefined
        data?:
          | {
              price?:
                | {
                    start?:
                      | {
                          currency?:
                            | {
                                contract?: string | undefined
                                name?: string | undefined
                                symbol?: string | undefined
                                decimals?: number | undefined
                                chainId?: number | undefined
                              }
                            | undefined
                          amount?:
                            | {
                                raw?: string | undefined
                                decimal?: number | undefined
                                usd?: number | undefined
                                native?: number | undefined
                              }
                            | undefined
                          netAmount?:
                            | {
                                raw?: string | undefined
                                decimal?: number | undefined
                                usd?: number | undefined
                                native?: number | undefined
                              }
                            | undefined
                        }
                      | undefined
                    end?:
                      | {
                          currency?:
                            | {
                                contract?: string | undefined
                                name?: string | undefined
                                symbol?: string | undefined
                                decimals?: number | undefined
                                chainId?: number | undefined
                              }
                            | undefined
                          amount?:
                            | {
                                raw?: string | undefined
                                decimal?: number | undefined
                                usd?: number | undefined
                                native?: number | undefined
                              }
                            | undefined
                          netAmount?:
                            | {
                                raw?: string | undefined
                                decimal?: number | undefined
                                usd?: number | undefined
                                native?: number | undefined
                              }
                            | undefined
                        }
                      | undefined
                  }
                | undefined
              time?:
                | {
                    start?: number | undefined
                    end?: number | undefined
                  }
                | undefined
            }
          | undefined
      }
    | undefined
  criteria?:
    | {
        kind?: 'token' | undefined
        data?:
          | {
              token?:
                | {
                    tokenId?: string | undefined
                    name?: string | undefined
                    image?: string | undefined
                    isSpam?: boolean | undefined
                    isNsfw?: boolean | undefined
                  }
                | undefined
              collection?:
                | {
                    id?: string | undefined
                    name?: string | undefined
                    image?: string | undefined
                    isSpam?: boolean | undefined
                    isNsfw?: boolean | undefined
                  }
                | undefined
            }
          | undefined
      }
    | undefined
  source?:
    | {
        [key: string]: unknown
      }
    | undefined
  feeBps?: number | undefined
  feeBreakdown?:
    | {
        kind?: string | undefined
        recipient?: string | undefined
        bps?: number | undefined
      }[]
    | undefined
  expiration: number
  isReservoir?: boolean | undefined
  isDynamic?: boolean | undefined
  createdAt: string
  updatedAt: string
  originatedAt?: string | undefined
  rawData?:
    | {
        [key: string]: unknown
      }
    | undefined
  isNativeOffChainCancellable?: boolean | undefined
  depth?:
    | {
        price?: number | undefined
        quantity?: number | undefined
      }[]
    | undefined
}

export type offerType = {
  id?: string | undefined
  price?:
    | {
        currency?:
          | {
              contract?: string | undefined
              name?: string | undefined
              symbol?: string | undefined
              decimals?: number | undefined
              chainId?: number | undefined
            }
          | undefined
        amount?:
          | {
              raw?: string | undefined
              decimal?: number | undefined
              usd?: number | undefined
              native?: number | undefined
            }
          | undefined
        netAmount?:
          | {
              raw?: string | undefined
              decimal?: number | undefined
              usd?: number | undefined
              native?: number | undefined
            }
          | undefined
      }
    | undefined
  maker?: string | undefined
  createdAt?: string | undefined
  validFrom?: number | undefined
  validUntil?: number | undefined
  floorDifferencePercentage?: number | undefined
  source?:
    | {
        id?: string | undefined
        domain?: string | undefined
        name?: string | undefined
        icon?: string | undefined
        url?: string | undefined
      }
    | undefined
  feeBreakdown?:
    | {
        kind?: string | undefined
        recipient?: string | undefined
        bps?: number | undefined
      }[]
    | undefined
  criteria?:
    | {
        kind?: 'token' | undefined
        data?:
          | {
              token?:
                | {
                    tokenId?: string | undefined
                    name?: string | undefined
                    image?: string | undefined
                    isSpam?: boolean | undefined
                    isNsfw?: boolean | undefined
                  }
                | undefined
              collection?:
                | {
                    id?: string | undefined
                    name?: string | undefined
                    image?: string | undefined
                    isSpam?: boolean | undefined
                    isNsfw?: boolean | undefined
                  }
                | undefined
            }
          | undefined
      }
    | undefined
  token?:
    | {
        contract?: string | undefined
        tokenId?: string | undefined
        kind?: string | undefined
        name?: string | undefined
        image?: string | undefined
        floorAskPrice?:
          | {
              currency?:
                | {
                    contract?: string | undefined
                    name?: string | undefined
                    symbol?: string | undefined
                    decimals?: number | undefined
                    chainId?: number | undefined
                  }
                | undefined
              amount?:
                | {
                    raw?: string | undefined
                    decimal?: number | undefined
                    usd?: number | undefined
                    native?: number | undefined
                  }
                | undefined
              netAmount?:
                | {
                    raw?: string | undefined
                    decimal?: number | undefined
                    usd?: number | undefined
                    native?: number | undefined
                  }
                | undefined
            }
          | undefined
        lastSalePrice?:
          | {
              currency?:
                | {
                    contract?: string | undefined
                    name?: string | undefined
                    symbol?: string | undefined
                    decimals?: number | undefined
                    chainId?: number | undefined
                  }
                | undefined
              amount?:
                | {
                    raw?: string | undefined
                    decimal?: number | undefined
                    usd?: number | undefined
                    native?: number | undefined
                  }
                | undefined
              netAmount?:
                | {
                    raw?: string | undefined
                    decimal?: number | undefined
                    usd?: number | undefined
                    native?: number | undefined
                  }
                | undefined
            }
          | undefined
        collection?:
          | {
              id?: string | undefined
              name?: string | undefined
              imageUrl?: string | undefined
              floorAskPrice?:
                | {
                    currency?:
                      | {
                          contract?: string | undefined
                          name?: string | undefined
                          symbol?: string | undefined
                          decimals?: number | undefined
                          chainId?: number | undefined
                        }
                      | undefined
                    amount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                    netAmount?:
                      | {
                          raw?: string | undefined
                          decimal?: number | undefined
                          usd?: number | undefined
                          native?: number | undefined
                        }
                      | undefined
                  }
                | undefined
            }
          | undefined
      }
    | undefined
}

export type NFT_Attributes = {
  AtkIncreaseValue: string
  Attack: string
  Category: string
  DefIncreaseValue: string
  Defense: string
  Expansion: string
  Health: string
  HpIncreaseValue: string
  'Max Supply': string
  Prestige: string
  Rarity: string
  skillPower: string
  SkillPower : string
  'Token Type ID': string
  heroClass: string
  itemEffect: string
  effectValue: string
}

export const heroes_v2 = {
  All: All,
  'Forest Knight': warrior,
  ForestKnight: warrior,
  Witch: Witch,
  Arcanist: Arcanist,
  ElfArcher: ArcherIcon,
  Priest: PriestIcon,
  Assassin: AssassinIcon,
  Mage: MageIcon,
  Thief: Thief,
  Barbarian: Barbarian,
  Paladin: Paladin,
  Raider: RaiderIcon,
  Ninja: Ninja,
  Samurai: SamuraiIcon,
  Livia: Livia,
  Wukong: Wukong,
  Damaratus: SpartanIcon,
  Shaman: ShamanIcon,
}

export const payment_tokens = {
  MATIC: { icon: MATIC, color: '#8247e5' },
  KNIGHT: { icon: Coin, color: '#FFC665' },
  WETH: { icon: WETH, color: '#fff' },
  WMATIC: { icon: MATIC, color: '#8247e5' },
  POL: { icon: MATIC, color: '#8247e5' },
}

export const NetworkContextName = 'NETWORK'
export const DEFAULT_CHAIN_ID = ChainId.MATIC
export const KNIGHT_TOKEN: ChainTokenMap = {
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D',
    18,
    'KNIGHT',
    'KNIGHT_TOKEN',
  ),
  [ChainId.MATIC_TESTNET]: new Token(
    ChainId.MATIC_TESTNET,
    '0x6748C7054CeaaAC461A3eaB903e98CF9f6b2387D',
    18,
    'KNIGHT',
    'KNIGHT_TOKEN',
  ),
}

export const CHAIN_PARAMS = {
  [ChainId.MATIC]: {
    chainName: 'Polygon Mainnet',
    chainId: Web3.utils.toHex(ChainId.MATIC),
    nativeCurrency: {
      name: 'MATIC',
      decimals: 18,
      symbol: 'MATIC',
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  [ChainId.RINKEBY]: {
    chainName: 'Rinkeby',
    chainId: Web3.utils.toHex(ChainId.RINKEBY),
    nativeCurrency: {
      name: 'MATIC',
      decimals: 18,
      symbol: 'MATIC',
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
}

export const MARKET_PLACE_ADDRESS = '0xff7bdbaed4fe92ced4e40845ab43575bde6d3488'

export const MERGER_ADDRESS = '0xA67bCEF75970121B3176c7015305e14D1a29c5D1'

export const SPECIAL_MERGER_ADDRESS =
  '0xfA1B43Db6ffE39D67267536F2514F434f0Cd0eee'
export const KNIGHT_TOKEN_ADDRESS = '0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D'

export const LOCKER_ADDRESS = '0x5649843da189e9e2545685ec2017ec29f4f71932'

export const DAO_ADDRESS = '0xcf0e4f398fe44835ea08b9723b55c2577c037ccd'

export const STEAMPUNK_CHEST = '0xf1eD5B88690dB3eD204544768f7526aFB00c32c4'
//testing Purposes
export const KNIGHT_CAT = '0x01F5f696D187e47538f28851dEf5097DaAD4a6A4'

export const CLOCKWORK_CHEST = '0x4d414daa5246a184df165ba0c545ecd9e4139b99'

export const Transfer_Helper = '0x0000000000c2d145a2526bD8C716263bFeBe1A72'
//
// export const ERC1155_CONTRACT_ADDRESS =
//   "0xdc401dd4e56eb6caaebcb5c34da93baabd36b314";

export const ERC1155_CONTRACT_ADDRESS =
  '0x9c09596d3d3691ea971f0b40b8cad44186868267'

export const WETH_CONTRACT_ADDRESS =
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619'
export const MATIC_CONTRACT_ADDRESS =
  '0x0000000000000000000000000000000000000000'

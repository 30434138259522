import { ConnectWallet, darkTheme } from '@thirdweb-dev/react'
import { Polygon } from '@thirdweb-dev/chains'

const displayBalanceToken = {
  [Polygon.chainId]: '0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D',
}
const supportedTokens = {
  [Polygon.chainId]: [
    {
      address: '0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D', // token contract address
      name: 'KNIGHT',
      symbol: 'KNIGHT',
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11324.png',
    },
    {
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // token contract address
      name: 'Tether USD',
      symbol: 'USDT',
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    },
    {
      address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', // token contract address
      name: 'USDC',
      symbol: 'USDC',
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    },
    {
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', // token contract address
      name: 'Wrapped ETH',
      symbol: 'WETH',
      icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
    },
  ],
}
export default function ConnectButton({ className }: { className?: string }) {
  return (
    <div className={className}>
      <ConnectWallet
        supportedTokens={supportedTokens}
        displayBalanceToken={displayBalanceToken}
        switchToActiveChain={true}
        theme={darkTheme({
          colors: {
            primaryButtonBg:
              'linear-gradient(90deg, #FFC665 0%, #FFAC20 78.35%)',
            primaryButtonText: 'white',
            modalBg: '#211c32',
            separatorLine: '#eda327',
          },
        })}
        modalSize="wide"
        modalTitle="Login"
        modalTitleIconUrl="https://chrono-games-company.ams3.cdn.digitaloceanspaces.com/Flat%20token%20logo.png"
        hideTestnetFaucet={false}
        showThirdwebBranding={false}
        termsOfServiceUrl="https://www.forestknight.io/terms-of-use"
        privacyPolicyUrl="https://www.forestknight.io/privacy-policy"
        welcomeScreen={{
          img: {
            src: '/Forest_knight_logo.png',
            width: 300,
            height: 200,
          },
          title: 'Welcome to Forest Knight Hub',
          subtitle: 'Connect your wallet to get started',
        }}
        // onConnect={async (wallet) => {
        //   const address = await wallet.getAddress()
        //   const signMessage = `
        //   Welcome to ForestKnight Hub!
        //   Click to sign in and accept the ForestKnight Hub Terms of Service (https://www.forestknight.io/terms-of-use) and Privacy Policy (https://www.forestknight.io/privacy-policy).
        //   This request will not trigger a blockchain transaction or cost any gas fees.
        //   Wallet: ${address}`
        //   wallet
        //     .signMessage(signMessage)
        //     .then((res) => {
        //       console.log('aaa', res)
        //     })
        //     .catch((err) => {
        //       console.error('aaa', err)
        //     })
        // }}
      />
    </div>
  )
}

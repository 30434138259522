/**
 * @description the root export file for getting the all assets used in the project
 */

import ForestKnight from './common/Navbar/ForestKnight_smalllogo.svg'
import Coin from './common/Navbar/Coin.svg'
import GetConnect from './common/Navbar/getConnect.svg'
import knightText from './common/Navbar/getknightText.svg'
import Discord from './common/footer/Dis.svg'
import Facebook from './common/footer/facebook.svg'
import Mail from './common/footer/Mail.svg'
import Telegram from './common/footer/Telegram.svg'
import Twitter from './common/footer/Twitter.svg'
import Yt from './common/footer/Yt.svg'
import Youtube from './common/footer/Youtube.svg'
import ForestLogo from './common/footer/ForestLogo.svg'
import GooglePlayStore from './common/footer/GooglePlayStore.svg'
import Rectangle from './common/footer/Rectangle.svg'
import HeroImg from './Home/HeroImg.png'
import CoinIcon from './Home/coinIcon.svg'
import Marketplace from './Home/Marketplace2.png'
import Vector from './Home/Vector.svg'
import Card from './common/Card/cardImage.svg'
import Heart from './common/Card/heart.png'
import Shield from './common/Card/shield.png'
import Sword from './common/Card/sword.png'
import nonft from './Profile/nonft.png'
import heart from './BuyNFT/heart.svg'
import sword from './BuyNFT/sword.svg'
import shield from './BuyNFT/shield.svg'
import energy from './BuyNFT/energy.svg'
import warrior from './common/filter//Forest_Knight_Circle.png'
import CardGroup from './General/cardgroup.png'
import CardListIcon from './General/cardList.png'
import Search from './General/Searchicon.png'
import Monster from './common/filter/Monsters.png'
import Arcanist from './common/filter/Arcanist_icon_circle.png'
import ArcherIcon from './common/filter/Archer_icon_circle.png'
import AssassinIcon from './common/filter/Assassin_icon_circle.png'
import ForestKnightHero from './common/filter/Forest_Knight_Circle.png'
import Barbarian from './common/filter/Barbarian_icon_circle.png'
import Equip from './common/filter/icon_circle_Ring.png'
import Golden from './common/filter/icon_circle_Necklace.png'
import InfernalSword from './common/filter/icon_circle_Sword.png'
import MageIcon from './common/filter/Mage_Icon_circle.png'
import Ninja from './common/filter/Ninja_icon_circle.png'
import Paladin from './common/filter/Paladin_icon_circle.png'
import PriestIcon from './common/filter/Priest_icon_circle.png'
import RaiderIcon from './common/filter/Raider_icon_circle.png'
import SamuraiIcon from './common/filter/Samurai_icon_circle.png'
import Thief from './common/filter/Thief_icon_circle.png'
import torso from './common/filter/icon_circle_Skin.png'
import runestone from './common/filter/icon_circle_Runestone.png'
import Witch from './common/filter/Witch_icon_circle.png'
import Livia from './common/filter/Livia_icon_circle.png'
import Wukong from './common/filter/Wukong_icon_circle.png'
import All from './common/filter/icon_circle_pink_All.png'
import profileBanner from './Profile/ProfileBanner.png'
import ProfileImg from './Profile/ProfileImg.png'
import ModalBackground from './common/Modals/modalBackground.svg'
import down from './common/Navbar/down-arrow.png'
import PaginateArrow from './Home/PaginateArrow.svg'
import inactiveCard from './General/inactiveGroup.png'
import activeList from './General/activeList.png'
import GridUnselected from './General/GridUnselected.png'
import listSelected from './General/listSelected.png'
import gateLogo from './common/footer/gateLogo.svg'
import quickSwapLogo from './common/footer/quickSwapLogo.svg'
import clawSmall from './common/footer/clawSmall.svg'
import gameSmall from './common/footer/gameSmall.svg'
import homeSmall from './common/footer/homeSmall.svg'
import marketplaceSmall from './common/footer/marketplaceSmall.svg'
import mountainSmall from './common/footer/mountainSmall.svg'
import profileSmall from './common/footer/profileSmall.svg'
import stakeSmall from './common/footer/stakeSmall.svg'
import subscribeRect from './common/footer/subscribeRectangle.svg'
import Value from './common/Card/value.png'
import Metamask from './walletconnect/metamask.svg'
import Walletconnect from './walletconnect/walletconnect.svg'
import FaceWallet from './walletconnect/FaceWallet.png'
import magicImage from './walletconnect/magicImage.svg'
import gateLogoSmall from './common/Navbar/gatelogosmall.svg'
import quickSwapLogoSmall from './common/Navbar/quickSwapLogo.svg'
import subscribeRectDark from './common/footer/subscribeRectDark.svg'
import MEXC from './common/Navbar/MEXC.png'
import AllCategory from './common/filter/icon_circle_AllCat.png'
import SpartanIcon from './common/filter/Spartan_icon_circle.png'
import ShamanIcon from './common/filter/Shaman_icon_circle.png'
import kriptomat from './common/Navbar/kriptomat.png'
import RefreshIcon from './BuyNFT/refresh-icon.svg'
import SendIcon from './BuyNFT/send-icon.svg'
import ShareIcon from './BuyNFT/share-icon.svg'
import steampunkChest from './common/header/chest.gif'
import MATIC from './common/token/matic.png'
import WETH from './common/token/weth.webp'
import WMATIC from './common/token/WMATIC.png'

export {
  MEXC,
  ForestKnight,
  ForestKnightHero,
  Paladin,
  Ninja,
  MageIcon,
  InfernalSword,
  Golden,
  Coin,
  Equip,
  Barbarian,
  AssassinIcon,
  GetConnect,
  knightText,
  Discord,
  Facebook,
  Mail,
  Telegram,
  Twitter,
  Yt,
  Youtube,
  ForestLogo,
  GooglePlayStore,
  Rectangle,
  HeroImg,
  Marketplace,
  Vector,
  Card,
  Shield,
  Value,
  Sword,
  Heart,
  nonft,
  heart,
  shield,
  sword,
  warrior,
  CardGroup,
  CardListIcon,
  Search,
  Monster,
  CoinIcon,
  Arcanist,
  ArcherIcon,
  Witch,
  torso,
  Thief,
  SamuraiIcon,
  RaiderIcon,
  PriestIcon,
  profileBanner,
  ProfileImg,
  ModalBackground,
  down,
  PaginateArrow,
  inactiveCard,
  activeList,
  GridUnselected,
  listSelected,
  gateLogo,
  quickSwapLogo,
  clawSmall,
  gameSmall,
  homeSmall,
  marketplaceSmall,
  mountainSmall,
  profileSmall,
  stakeSmall,
  subscribeRect,
  gateLogoSmall,
  quickSwapLogoSmall,
  Metamask,
  Walletconnect,
  energy,
  magicImage,
  subscribeRectDark,
  FaceWallet,
  Livia,
  Wukong,
  All,
  AllCategory,
  SpartanIcon,
  kriptomat,
  RefreshIcon,
  SendIcon,
  ShareIcon,
  steampunkChest,
  ShamanIcon,
  runestone,
  MATIC,
  WETH,
  WMATIC,
}

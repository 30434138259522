import React from 'react'
import styles from '../../styles/common/Modal.module.scss'
import { ModalBackground, Walletconnect } from '../../assets'

interface ModalProps {
  content: any
  triggerModalClose: () => void
}

/**
 *
 * @param props the props for the component
 * @returns the root modal component
 */

const Modal: React.FC<ModalProps> = (props: any) => (
  <div className={styles.ModalContainer}>
    <div className={styles.ModalBackground} onClick={props.triggerModalClose}>
      {null}
    </div>
    <div
      className={styles.ModalContent}
      style={
        props.content.type.name !== 'WalletConnection'
          ? {
              width: 'fitContent',
              height: 'fitContent',
              backgroundImage: `url(${ModalBackground})`,
              backgroundPosition: 'center center',
              overflowY: 'scroll',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : {
              width: '500px',
              height: '450px',
              backgroundImage: `url(${ModalBackground})`,
              backgroundPosition: 'center center',
              overflowY: 'scroll',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
      }
    >
      <div className={styles.ModalContentContainer}>{props.content}</div>
    </div>
  </div>
)

export default Modal

import { Coin, ForestKnight } from 'assets'
import { GET_KNIGHT_ITEMS, navArray } from 'constants/header-items'
import { SetStateAction, useState } from 'react'
import Arrow from '../Arrow'

const DropDown: React.FC<{
  history: any
  pathname: string
  setClicked: React.Dispatch<SetStateAction<boolean>>
}> = ({ history, pathname, setClicked }) => {
  const [openedIndex, setOpenedIndex] = useState<
    number | undefined | 'KNIGHT'
  >()
  const handleClick = (index: number) => {
    const selectedItem = navArray[index]
    if (selectedItem.link.href) {
      window.open(selectedItem.link.href, '_blank')
      setClicked(false)
    } else if (selectedItem.link.pathname) {
      if (selectedItem.link.pathname !== '#') {
        if (pathname === selectedItem.link.pathname) {
          openedIndex === index
            ? setOpenedIndex(undefined)
            : setOpenedIndex(index)
        } else {
          history.push(selectedItem.link.pathname)
          setClicked(false)
        }
      } else {
        openedIndex === index
          ? setOpenedIndex(undefined)
          : setOpenedIndex(index)
      }
    }
  }
  return (
    <ul className="header_mobile_dropdown">
      <li className="header_mobile_dropdown_logo">
        <img src={ForestKnight} alt="logo" />
      </li>
      {navArray.map((item, index) => (
        <li
          key={item.label}
          className="header_mobile_dropdown_button"
          onClick={() => handleClick(index)}
        >
          <div
            className={`header_mobile_dropdown_button_self ${
              pathname === item.link.pathname
                ? 'mobile_button_path_checked'
                : pathname === '/chest-steampunk' ||
                  pathname === '/chest-clockwork'
                ? item.label === 'Chests' && 'mobile_button_path_checked'
                : pathname === '/merge' || pathname === '/forge'
                ? item.label === 'Crafting' && 'mobile_button_path_checked'
                : pathname === '/profile-v1' || pathname === '/profile-v2'
                ? item.label === 'Profile' && 'mobile_button_path_checked'
                : pathname === '/marketplace-v1' ||
                  pathname === '/marketplace-v2'
                ? item.label === 'Marketplace' && 'mobile_button_path_checked'
                : null
            }`}
          >
            <item.icon />
            <p>{item.label}</p>
            {item.subMenus && (
              <Arrow
                clicked={openedIndex === index}
                className="header_mobile_dropdown_button_self_Arrow"
              />
            )}
          </div>

          {index === openedIndex && (
            <>
              {item.subMenus.map((item1, index) => (
                <li
                  onClick={(e) => {
                    e.stopPropagation()
                    if (item1.link) {
                      if (item1.link.href) {
                        setClicked(false)
                        window.open(item1.link.href, '_blank')
                      } else {
                        setClicked(false)
                        history.push(item1.link.pathname)
                      }
                    }
                  }}
                  key={item1.label}
                  className="header_mobile_dropdown_button_list_item"
                >
                  <item1.icon />
                  <p>
                    {item1.label}
                    {item1.soon && ' [Soon]'}
                  </p>
                </li>
              ))}
            </>
          )}
        </li>
      ))}
      <li className="header_mobile_dropdown_GETKNIGHT">
        <button
          className="header_mobile_dropdown_GETKNIGHT_button"
          onClick={() => {
            if (openedIndex === 'KNIGHT') {
              setOpenedIndex(undefined)
            } else {
              setOpenedIndex('KNIGHT')
            }
          }}
        >
          <img src={Coin} alt="knight" />
          <p>GET $KNIGHT</p>
        </button>
        {openedIndex === 'KNIGHT' && (
          <>
            {GET_KNIGHT_ITEMS.map((item, index) => (
              <div
                key={item.title}
                onClick={() => {
                  setClicked(false)
                  window.open(item.href, '_blank')
                }}
              >
                {item.icon && <img src={item.icon} />}
                <p
                  style={{
                    left: !item.icon ? 'unset' : '',
                  }}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </>
        )}
      </li>
    </ul>
  )
}

export default DropDown

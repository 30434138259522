import { createSlice } from '@reduxjs/toolkit';



interface InitialState {
    loadingModalVisible: boolean;
    transactionFailModalVisible: boolean;
    transactionSuccessModalVisible: boolean;
  }
  
  const initialState: InitialState = {
    loadingModalVisible: false,
    transactionFailModalVisible: false,
    transactionSuccessModalVisible: false,
  };
  

export const ChestBurnModalsSlice = createSlice({
  name: 'ChestBurnModals',
  initialState,
  reducers: {
    setLoadingModalVisible: (state, { payload }: { payload: boolean }) => {
      state.loadingModalVisible = payload;
      // Optionally reset other modals
      if (payload) {
        state.transactionFailModalVisible = false;
        state.transactionSuccessModalVisible = false;
      }
    },
    setTransactionFailModalVisible: (state, { payload }: { payload: boolean }) => {
      state.transactionFailModalVisible = payload;
      // Hide loading when showing fail modal
      if (payload) {
        state.loadingModalVisible = false;
      }
    },
    setTransactionSuccessModalVisible: (state, { payload }: { payload: boolean }) => {
      state.transactionSuccessModalVisible = payload;
      // Hide loading when showing success modal
      if (payload) {
        state.loadingModalVisible = false;
      }
    },
  },
});



  export const {
    setLoadingModalVisible,
    setTransactionFailModalVisible,
    setTransactionSuccessModalVisible,
  } = ChestBurnModalsSlice.actions;
  
  export default ChestBurnModalsSlice.reducer;
  
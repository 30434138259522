import {
  toWei,
  useAddress,
  useContract,
  useSDK,
  useTokenBalance,
} from '@thirdweb-dev/react'
import { KNIGHT_TOKEN_ADDRESS, LOCKER_ADDRESS } from 'constants/index'
import KNIGHT_ABI from 'constants/abis/erc20.json'
import { useCallback } from 'react'

export default function useKnightContract() {
  const sdk = useSDK()
  const address = useAddress()
  const { contract: knightContract } = useContract(
    KNIGHT_TOKEN_ADDRESS,
    KNIGHT_ABI,
  )

  /**
   * @description This function is responsible for getting $knight balance globally in project
   * @param address accepts user's wallet address as param
   * @returns $knight balance
   */
  const useKnightBalance = (account?: string) => {
    const {
      data: tokenData,
      isLoading,
      error,
    } = useTokenBalance(knightContract, account || address)
    if (!isLoading) {
      return Number(tokenData.value) / 1e18
    } else if (error !== null) {
      console.error(error)
      return 0
    }
  }

  /**
   * @description function for getting user's allowance for locker contract
   * @param address accepts user's wallet address
   * @returns user's allownace in number
   */
  const getAllowance = useCallback(
    async (spender: string) => {
      if (knightContract)
        try {
          const args = [address, spender]
          const allowance = await knightContract.call('allowance', args)
          return Number(allowance) / 1e18
        } catch (err) {
          console.error(err)
          return 0
        }
    },
    [knightContract],
  )

  /**
   * @description $knight approval function for locker contract
   * @param amount user's desired amount for approving
   * @returns approval transaction receipt
   */
  const approveKnight = useCallback(
    async (amount: string, spender: string) => {
      if (knightContract)
        try {
          const contract = await sdk.getContract(KNIGHT_TOKEN_ADDRESS)
          const args = [spender, toWei(amount)]
          const tx = await contract.call('approve', args)
          return tx
        } catch (err) {
          console.error(err)
          return 'error'
        }
    },
    [knightContract],
  )

  return {
    useKnightBalance,
    getAllowance,
    approveKnight,
  }
}

import React, { createContext, useContext, useState } from "react";
import "./txLoading.scss"

interface txcontext {
    txLoading: boolean
    setTxLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const txContext = createContext<txcontext>(undefined)

export default function TxLoadingContext({ children }: { children: any }) {

    const [txLoading, setTxLoading] = useState(false)

    if (txLoading === true) {
        document.body.style.overflow = "hidden"
    } else {
        document.body.style.overflowY = "scroll"
    }

    return (
        <txContext.Provider value={{ txLoading, setTxLoading }}>
            <>
                {
                    txLoading === true && (
                        <LoadingOverlay/>
                    )
                }
                {children}
            </>
        </txContext.Provider>
    )
}

export const useTxLoadingContext = () => {
    return useContext(txContext)
}

const LoadingOverlay : React.FC = () => {
    return (
        <div className="LoadingOverlay_wrapper">
            <section className="LoadingOverlay_container">
                <div className="LoadingOverlay_topBox">
                    <div className="LoadingOverlay_topBox_loadingBar">
                        <div className="lodaing_dot_1" />
                        <div className="lodaing_dot_2" />
                        <div className="lodaing_dot_3" />
                        <div className="lodaing_dot_4" />
                        <div className="lodaing_dot_5" />
                        <div className="lodaing_dot_6" />
                        <div className="lodaing_dot_7" />
                        <div className="lodaing_dot_8" />

                    </div>
                </div>
                <div className="LoadingOverlay_bottomBox">
                    <p>Your transaction is in progress...</p>
                </div>
            </section>
        </div>
    )
}
import token from './token.svg'
import lock from './lock.svg'
import estimate from './estimate.svg'
import priceRaise from './priceRaise.svg'
import priceDown from './priceDown.svg'
import cardsContainerBack from './cardsContainerBack.svg'
import banner from './banner.svg'
import votingActivities from './votingActivities.svg'
import cardImage from './cardImage.svg'
import littleGuid from './littleGuid.svg'
import PaginateArrow from './PaginateArrow.svg'
import modalsBack from './modalsBack.png'
import checked from './checked.png'
import backIcon from './backIcon.svg'
import votingpower from './votingpower.svg'

export {
  token,
  lock,
  estimate,
  priceRaise,
  priceDown,
  banner,
  votingActivities,
  cardsContainerBack,
  cardImage,
  littleGuid,
  PaginateArrow,
  modalsBack,
  checked,
  backIcon,
  votingpower
}

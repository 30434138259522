import { ThirdwebSDK } from '@thirdweb-dev/sdk'
import { getAddress } from 'viem'
import { providers } from 'ethers'
import { ProviderNotFoundError, createConnector } from '@wagmi/core'

export type ThirdWebParameters = {
  sdk?: ThirdwebSDK | undefined
  shimDisconnect?: boolean | undefined
}

thirdWebWallet.type = 'thirdweb' as const
export function thirdWebWallet(parameters: ThirdWebParameters) {
  const { shimDisconnect = false, sdk: thirdWebSdk } = parameters

  type Provider = providers.Provider | undefined
  type Properties = {}
  type StorageItem = { 'thirdweb.disconnected': true }

  return createConnector<Provider, Properties, StorageItem>((config) => ({
    id: 'thirdweb',
    name: 'ThirdWeb',
    type: thirdWebWallet.type,
    async connect() {
      const provider = await this.getProvider()
      if (!provider) throw new ProviderNotFoundError()

      const accounts = await this.getAccounts()
      const chainId = 137

      provider.on('disconnect', this.onDisconnect.bind(this))

      // Remove disconnected shim if it exists
      if (shimDisconnect)
        await config.storage?.removeItem('thirdweb.disconnected')

      return { accounts, chainId }
    },
    async disconnect() {
      const provider = await this.getProvider()
      if (!provider) throw new ProviderNotFoundError()

      provider.removeListener('disconnect', this.onDisconnect.bind(this))

      // Add shim signalling connector is disconnected
      if (shimDisconnect)
        await config.storage?.setItem('thirdweb.disconnected', true)
    },
    async getAccounts() {
      const provider = await this.getProvider()
      if (!provider) throw new ProviderNotFoundError()
      return [getAddress(await thirdWebSdk?.getSigner()?.getAddress()!)]
    },
    async getProvider() {
      var provider = await thirdWebSdk?.getProvider()
      if (!provider) throw new ProviderNotFoundError()
      return provider
    },
    async getChainId() {
      return 137
    },
    async isAuthorized() {
      try {
        const isDisconnected =
          shimDisconnect &&
          // If shim exists in storage, connector is disconnected
          (await config.storage?.getItem('thirdweb.disconnected'))
        if (isDisconnected) return false

        const accounts = await this.getAccounts()
        return !!accounts.length
      } catch {
        return false
      }
    },
    onAccountsChanged() {
      // Not relevant for ThirdWeb?
    },
    onChainChanged() {
      // Not relevant for ThirdWeb?
    },
    onDisconnect() {
      config.emitter.emit('disconnect')
    },
  }))
}

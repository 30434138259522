import './Buttons.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../state'
import { setStakeModalStatus } from '../stakingSlices/ModalsSlice'
import useLockerContract from 'hooks/Thirdweb/useLockerContract'
import { useChain, useSwitchChain } from '@thirdweb-dev/react'
import { Polygon } from '@thirdweb-dev/chains'

export default function UnStakeButton({
  width,
  stakeLoading,
}: {
  width?: number
  stakeLoading: boolean
}) {
  let width2: number
  width2 = (width / 1920) * 100
  const dispatch = useDispatch()
  const chain = useChain()
  const switchChain = useSwitchChain()
  const { unlock } = useLockerContract()
  const modal = useSelector((state: AppState) => state.stakingModals)
  const [loading, setLoading] = useState<
    'LOADING...' | 'SWITCH CHAIN' | 'UNLOCK'
  >('LOADING...')

  useEffect(() => {
    if (chain) {
      if (chain.chainId === Polygon.chainId) {
        if (!stakeLoading) setLoading('UNLOCK')
      } else {
        setLoading('SWITCH CHAIN')
      }
    }
  }, [chain, stakeLoading])


  return (
    <button
      onClick={() => {
        if (loading === 'UNLOCK') {
          setLoading('LOADING...')
          unlock(String(modal.stakeModalValue))
            .then((res) => {
              if (res === 'error') {
                console.error('unlock error')
                dispatch(setStakeModalStatus('fail'))
              } else {
                dispatch(setStakeModalStatus('success'))
              }
            })
            .catch((err) => {
              console.error('unlock error', err)
              dispatch(setStakeModalStatus('fail'))
            })
        } else if (loading === 'SWITCH CHAIN') {
          switchChain(Polygon.chainId)
        }
      }}
      className="StakeButton"
    >
      {loading}
    </button>
  )
}

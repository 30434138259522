import { toWei, useContract, useSDK } from '@thirdweb-dev/react'
import { LOCKER_ADDRESS } from 'constants/index'
import LOCKER_ABI from '../../abis/lockerABI.json'
import { useCallback } from 'react'

export default function useLockerContract() {
  const sdk = useSDK()
  const { contract: lockerContract } = useContract(LOCKER_ADDRESS, LOCKER_ABI)

  /**
   * @description this function returns whole value locked in the staking contract
   * @param lockerContract accepts locker contract instance
   * @returns total knight locked in project by users
   */
  const getTotalLocked = useCallback(async () => {
    if (lockerContract)
      try {
        const response = await lockerContract.call('totalLockingAmount')
        return response / 1e18
      } catch (err) {
        console.error(err)
        return 0
      }
  }, [lockerContract])

  /**
   * @description this function returns whole value user staked
   * @param address function accepts user's wallet address as input
   * @returns user's locked amount
   */
  const getPersonalLockInfo = useCallback(
    async (address: string) => {
      if (lockerContract)
        try {
          const args = [address]
          const response = await lockerContract.call('getLockerState', args)
          return response.lockedAmount / 1e18
        } catch (err) {
          console.error(err)
          return 0
        }
    },
    [lockerContract],
  )

  /**
   * @description this function locks money in staking contract
   * @param address function accepts user's desired amount to stake
   * @returns lock transaction receipt
   */
  const lock = useCallback(async (amount: string) => {
    try {
      const contract = await sdk.getContract(LOCKER_ADDRESS)
      const args = [toWei(amount), [], []]
      const tx = await contract.call('lock',args)
      return tx
    } catch (err) {
      console.error(err)
      return "error"
    }
  }, [sdk])

  /**
   * @description this function withdraws money from staking contract
   * @param address function accepts user's desired amount to withdraw
   * @returns unlock transaction receipt
   */
  const unlock = useCallback(async (amount: string) => {
    try {
      const contract = await sdk.getContract(LOCKER_ADDRESS)
      const args = [toWei(amount), [], []]
      const tx = await contract.call('unlock', args)
      return tx
    } catch (err) {
      console.error(err)
      return 'error'
    }
  }, [sdk])

  return {
    getTotalLocked,
    getPersonalLockInfo,
    lock,
    unlock,
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from '../../redux/store'
import style from '../../styles/cardComponents/cancelAuctionModal.module.scss'
import { toggleCancelAuctionModal } from 'redux/slices/modalSlice'
import usePersistentToast from 'hooks/usePersistentToast'
import { triggerNFTReload } from '../../redux/slices/nftSlice'
import useNFT from '../../hooks/useNFT'
import { useTxLoadingContext } from '../../contexts/txLoadingContext'
import useMarketPlaceContract from 'hooks/Thirdweb/useMArketPlaceContract'

/**
 *
 * @returns the cancel auction modal component
 */

const CancelAuctionModal: React.FC = () => {
  const { setTxLoading } = useTxLoadingContext()
  // consuming the redux state for getting the destructured data
  /*
  {
    NFTID: string
    saleID
   }
   */
  const {
    modalState: { modalInfo },
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const { cancelSale } = useMarketPlaceContract()
  // @ts-ignore
  const NFT: any = useNFT(modalInfo.NFTID)

  /**
   * @description custom toast config for cancel in progress
   */

  const cancelInProgressToast = usePersistentToast(
    'Cancelling in progress',
    'info',
  )

  /**
   * @description method for initiating the cancel process for auction for NFT
   */

  const submitHandler = async () => {
    cancelInProgressToast.trigger()
    setTxLoading(true)

    // @ts-ignore
    const tx = await cancelSale(Number(modalInfo.saleID))
    if (typeof tx !== 'string') {
      cancelInProgressToast.dismiss()
      dispatch(toggleCancelAuctionModal({}))

      toast.success('Listing cancelled Successfully!')

      window.location.replace('/profile-v1')
      dispatch(triggerNFTReload())
      setTxLoading(false)
    } else {
      setTxLoading(false)
      cancelInProgressToast.dismiss()

      toast.error('Listing Cancelled Failed!')
      dispatch(toggleCancelAuctionModal({}))
      // window.location.replace('/profile')
      dispatch(triggerNFTReload())

      // transactionInProgressToast.dismiss()
      //
      // dispatch(toggleListingModalError('Please try again!'))
      // dispatch(toggleListingModal(currentData.tokenId))
      // dispatch(
      //   toggleListingModalAfter({
      //     NFTid: currentData.tokenId.toString(),
      //     tokenId: currentData.tokenId.toString(),
      //     NFTname: `${currentData.category} #${currentData.tokenId}`,
      //   }),
      // )
    }
  }

  return (
    <div className={style.modalContainer}>
      <img
        src={NFT?.image}
        className={style.cardImage}
        alt={'User Profile Page'}
      />
      <h2 className={style.productName}>
        {NFT?.category} #{NFT?.tokenId}
      </h2>
      <div className={style.purchaseState}>
        Are you sure you want to cancel the listing?
      </div>
      <div className={style.actionButtonContainer}>
        <div className={style.actionButtonGreen} onClick={submitHandler}>
          YES, Cancel Listing
        </div>
        <div
          className={style.actionButtonYellow}
          onClick={() =>
            dispatch(
              //@ts-ignore
              toggleCancelAuctionModal({}),
            )
          }
        >
          No, I changed my mind
        </div>
      </div>
    </div>
  )
}

export default CancelAuctionModal

import { createSlice } from '@reduxjs/toolkit'

export type proposal = {
  type: 'Active' | 'Finished' | 'Starting Soon'
  proposalID: number
  votingDeadline: number
  rewardDistributionDeadline: number
  knightReward: number
  numberOfOptions: number
  startTime: number
  optionsVoteStatus: [number, number]
  totalVote: number
  title: string
  description: string
  imageURL: string
  options: ['yes', 'no']
}

interface initial_state {
  value: 'All' | 'Active' | 'Starting Soon' | 'Finished'
  firstIndex: number
  lastIndex: number
  items: proposal[]
}

const initialState: initial_state = {
  value: 'All',
  firstIndex: 0,
  lastIndex: 3,
  items: [],
}

export const StakingFilterSlice = createSlice({
  name: 'StakingFilter',
  initialState,
  reducers: {
    // filter value is All
    setFilterAll: (state) => {
      state.value = 'All'
      state.firstIndex = 0
      state.lastIndex = 3
    },
    // filter value is Active
    setFilterActive: (state) => {
      state.value = 'Active'
      state.firstIndex = 0
      state.lastIndex = 3
    },
    // filter value is StartingSoon
    setFilterStarting: (state) => {
      state.value = 'Starting Soon'
      state.firstIndex = 0
      state.lastIndex = 3
    },
    // filter value is Finished
    setFilterFinished: (state) => {
      state.value = 'Finished'
      state.firstIndex = 0
      state.lastIndex = 3
    },
    setNextPage: (state) => {
      state.firstIndex += 3
      state.lastIndex += 3
    },
    setLastPage: (state) => {
      state.firstIndex -= 3
      state.lastIndex -= 3
    },

    setPage: (
      state,
      { payload }: { payload: { first: number; last: number } | undefined },
    ) => {
      state.firstIndex = payload.first
      state.lastIndex = payload.last
    },

    setItems: (state, { payload }: { payload: any[] }) => {
      state.items = payload
    },
  },
})

export const {
  setFilterAll,
  setFilterActive,
  setFilterStarting,
  setFilterFinished,
  setNextPage,
  setLastPage,
  setItems,
  setPage,
} = StakingFilterSlice.actions

export default StakingFilterSlice.reducer

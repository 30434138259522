import React, { useEffect, useRef, useState } from 'react'
import './Modals.scss'
import DragScale from '../dragScale/DragScale'
import { useDispatch, useSelector } from 'react-redux'
import {
  setClaimingModalStatus,
  setClaimingModalVisible,
  setStakeModalStatus,
  setStakeModalVisible,
  setStakingValue,
  setVoteModalFail,
  setVoteModalSuccess,
  setVoteModalVisible,
} from '../stakingSlices/ModalsSlice'
import { AppState } from '../../../state/index'
import { token } from '../../../assets/Staking/StakingImages'
import CheckBox from '../checkBox/CheckBox'
import GoToDashboardButton from '../buttons/GoToDashboardButton'
import StakeButton from '../buttons/StakeButton'
import { useActiveWeb3React } from '../../../hooks'
import UnStakeButton from '../buttons/UnStakeButton'
import { proposal } from '../stakingSlices/StakingFilterSlice'
import { useDAO } from '../../../hooks/useDAO'
import { useAddress } from '@thirdweb-dev/react'
import useLockerContract from 'hooks/Thirdweb/useLockerContract'
import useKnightContract from 'hooks/Thirdweb/useKnightContract'
import useDaoContract from 'hooks/Thirdweb/useDaoContract'

export default function StakingModals() {
  const modal = useSelector((state: AppState) => state.stakingModals)
  return (
    <>
      {modal.stakeModalVisible === true && (
        <StakeModal
          value={modal.stakeModalValue}
          isLock={modal.isLock}
          success={modal.stakeModalSuccess}
          fail={modal.stakeModalFailure}
        />
      )}
      {modal.claimingModal.visible === true && (
        <ClaimModal
          fail={modal.claimingModal.fail}
          success={modal.claimingModal.success}
        />
      )}
      {modal.voteModal.visible === true && (
        <VoteModal
          data={modal.voteModal.data}
          success={modal.voteModal.success}
          fail={modal.voteModal.fail}
        />
      )}
    </>
  )
}

const StakeModal: React.FC<{ value; isLock; success; fail }> = ({
  value,
  isLock,
  success,
  fail,
}: {
  value: number
  isLock: boolean
  success: boolean
  fail: boolean
}) => {
  const dispatch = useDispatch()
  const address = useAddress()
  const { getPersonalLockInfo } = useLockerContract()
  const { useKnightBalance } = useKnightContract()

  const balance = useKnightBalance()
  const [totalStaked, setTotalStaked] = useState({
    loading: true,
    value: 0,
  })
  useEffect(() => {
    if (address && isLock) {
      getPersonalLockInfo(address)
        .then((res) => {
          setTotalStaked({
            loading: false,
            value: res,
          })
        })
        .catch((err) => {
          console.error(err)
          setTotalStaked({
            loading: false,
            value: 0,
          })
        })
    }
  }, [address])

  useEffect(() => {
    return () => {
      dispatch(setStakingValue(0))
    }
  }, [])

  return (
    <>
      <div className="all_modals_wrapper">
        <section className="stake_modal_container">
          {!success && !fail ? (
            <>
              <div className="stakeModal_topBox">
                <p>{!isLock ? 'Lock' : 'Unlock'} Your $KNIGHT</p>
              </div>
              <div className="stakeModal_middleBox">
                {!isLock ? (
                  <DragScale value={balance} />
                ) : (
                  <DragScale value={totalStaked.value} />
                )}
                <div className="stakeModal_middleBox_infoBox">
                  <div className="stakeModal_middleBox_infoBox_texts">
                    <h2>Total amount to {!isLock ? 'Lock' : 'Unlock'}</h2>
                  </div>
                  <div className="stakeModal_middleBox_infoBox_value">
                    <div className="stakeValueBox">
                      <p>{value}</p>
                      <img src={token} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="stakeModal_bottomBox">
                <GoToDashboardButton
                  width={
                    (200 * 1900) / window.innerWidth > 400
                      ? 400
                      : (200 * 1900) / window.innerWidth
                  }
                />
                {!isLock ? (
                  <StakeButton
                    address={address}
                    width={
                      (200 * 1900) / window.innerWidth > 400
                        ? 400
                        : (200 * 1900) / window.innerWidth
                    }
                  />
                ) : (
                  <UnStakeButton
                    stakeLoading={totalStaked.loading}
                    width={
                      (200 * 1900) / window.innerWidth > 400
                        ? 400
                        : (200 * 1900) / window.innerWidth
                    }
                  />
                )}
              </div>
            </>
          ) : success ? (
            <>
              <div className="modals_container_success_image"></div>
              <div className="modals_container_success_textsBox">
                <h1>Power UP!</h1>
                <p>
                  Your $KNIGHT was successfully{' '}
                  {!isLock ? 'Locked' : 'Unlocked'}!
                </p>
              </div>
              <div className="modals_container_success_buttonBox">
                <GoToDashboardButton
                  text="GO TO DASHBOARD"
                  width={
                    (200 * 1900) / window.innerWidth > 400
                      ? 400
                      : (200 * 1900) / window.innerWidth
                  }
                  onClick={() => {
                    dispatch(setStakeModalVisible(true))
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="modals_container_success_image"></div>
              <div className="modals_container_success_textsBox">
                <h1>Failed!</h1>
                <p style={{ color: 'red' }}>
                  There was an issue, please try again!
                </p>
              </div>
              <div className="modals_container_success_buttonBox">
                <GoToDashboardButton
                  text="GO TO DASHBOARD"
                  width={
                    (200 * 1900) / window.innerWidth > 400
                      ? 400
                      : (200 * 1900) / window.innerWidth
                  }
                  onClick={() => {
                    dispatch(setStakeModalVisible(true))
                  }}
                />
              </div>
            </>
          )}
        </section>
      </div>
    </>
  )
}

const ClaimModal: React.FC<{ success: boolean; fail: boolean }> = ({
  success,
  fail,
}) => {
  const dispatch = useDispatch()

  return (
    <div
      className="ClaimModal_wrapper"
      onClick={() => {
        if (!success && !fail) {
          return
        } else {
          dispatch(setClaimingModalVisible())
        }
      }}
    >
      <section
        className="ClaimModal_container"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {!success && !fail ? (
          <>
            <div className="ClaimModal_container_title">
              <h1>Claiming Rewards</h1>
            </div>
            <div className="ClaimModal_container_loadingBar">
              <div />
            </div>
            <div className="ClaimModal_container_message">
              <p>Please confirm Reward claiming in your wallet</p>
            </div>
          </>
        ) : success ? (
          <>
            <div className="ClaimModal_container_success_image"></div>
            <div className="ClaimModal_container_success_textsBox">
              <h1>Power UP!</h1>
              <p>
                You have successfully claimed your
                <br /> Rewards!
              </p>
            </div>
            <div className="ClaimModal_container_success_buttonBox">
              <GoToDashboardButton
                text="GO TO DASHBOARD"
                width={200}
                onClick={() => {
                  dispatch(setClaimingModalVisible())
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="ClaimModal_container_success_image"></div>
            <div className="ClaimModal_container_success_textsBox">
              <h1>Power UP!</h1>
              <p style={{ color: 'red' }}>
                There was an issue, please try again!
              </p>
            </div>
            <div className="ClaimModal_container_success_buttonBox">
              <GoToDashboardButton
                text="GO TO DASHBOARD"
                width={200}
                onClick={() => {
                  dispatch(setClaimingModalVisible())
                }}
              />
            </div>
          </>
        )}
      </section>
    </div>
  )
}

const VoteModal: React.FC<{
  data: proposal
  success: boolean
  fail: boolean
}> = ({ data, success, fail }) => {
  const percent = data.totalVote
    ? (data.optionsVoteStatus[0] / data.totalVote) * 100
    : 50
  const dispatch = useDispatch()
  const address = useAddress()
  const { getUserStats } = useDaoContract()
  const { vote } = useDaoContract()

  const [loading, setLoading] = useState(false)
  const [votingPower, setVotingPower] = useState<number | undefined>(0)

  useEffect(() => {
    if (address) {
      getUserStats(address).then((res) => {
        if (res) setVotingPower(Number(res.votingPower.toFixed(2)))
      })
    }
  }, [address])

  return (
    <div
      className="VoteModal_wrapper"
      onClick={() => {
        dispatch(setVoteModalVisible())
      }}
    >
      <section
        className="VoteModal_container"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="VoteModal_container_content">
          {!success && !fail ? (
            <>
              <div className="VoteModal_container_title">
                <h1>{data.title}</h1>
              </div>
              <div className="VoteModal_container_detail">
                <div className="imageBox">
                  <img src={data.imageURL} alt="voteImage" />
                </div>
                <div className="dataBox">
                  <h1>Current Voting Status</h1>
                  <div className="progressContainer">
                    <div className="progressBar">
                      <div
                        className="Yes"
                        style={{ width: `${percent.toFixed(2)}%` }}
                      >
                        <p>{data?.options?.[0]}</p>
                      </div>
                      <div
                        className="No"
                        style={{ width: `${(110 - percent).toFixed(2)}%` }}
                      >
                        <p>{data?.options?.[1]}</p>
                      </div>
                    </div>
                    <div className="percents">
                      <div
                        className="Yes"
                        style={{ width: `${percent.toFixed(2)}%` }}
                      >
                        <p>{percent.toFixed(2)}%</p>
                      </div>
                      <div
                        className="No"
                        style={{ width: `${(100 - percent).toFixed(2)}%` }}
                      >
                        <p>{(100 - percent).toFixed(2)}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="VoteModal_container_buttons">
                <button
                  className="voteNo"
                  onClick={async () => {
                    if (!loading) {
                      if (votingPower === 0) {
                        dispatch(setVoteModalFail())
                        return
                      }
                      setLoading(true)
                      await vote(data.proposalID, 1)
                        .then((res) => {
                          if (res) {
                            if (res !== 'error') {
                              dispatch(setVoteModalSuccess())
                            } else {
                              dispatch(setVoteModalFail())
                            }
                            setLoading(false)
                          }
                        })
                        .catch((err) => {
                          console.error(err)
                          setLoading(false)
                          dispatch(setVoteModalFail())
                        })
                    }
                  }}
                >
                  {loading ? 'Wait!' : 'Vote "No"'}
                </button>
                <button
                  className="voteYes"
                  onClick={async () => {
                    if (!loading) {
                      if (votingPower === 0) {
                        dispatch(setVoteModalFail())
                        return
                      }
                      setLoading(true)
                      await vote(data.proposalID, 0)
                        .then((res) => {
                          if (res) {
                            if (res !== 'error') {
                              dispatch(setVoteModalSuccess())
                            } else {
                              console.error('Vote failure:')
                              dispatch(setVoteModalFail())
                            }
                            setLoading(false)
                          }
                        })
                        .catch((err) => {
                          console.error('Vote failure:', err)
                          setLoading(false)
                          dispatch(setVoteModalFail())
                        })
                    }
                  }}
                >
                  {loading ? 'Wait!' : 'Vote "Yes"'}
                </button>
              </div>
            </>
          ) : success ? (
            <>
              <div className="VoteModal_container_success_title">
                <h1>{data.title}</h1>
              </div>
              <div className="VoteModal_container_success_image">
                <img src={data.imageURL} alt="vote item" />
              </div>
              <div className="VoteModal_container_success_text">
                <h1>Thank You!</h1>
                <p>Your vote has been submitted!</p>
              </div>
              <div className="VoteModal_container_success_button">
                <GoToDashboardButton
                  text="GO TO DASHBOARD"
                  width={180}
                  onClick={() => {
                    dispatch(setVoteModalVisible())
                    dispatch(setVoteModalSuccess())
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="VoteModal_container_fail_image"></div>
              <div className="VoteModal_container_fail_text">
                <h1>An error has occured!</h1>
                <p>Please try again in a while.</p>
              </div>
              <div className="VoteModal_container_fail_button">
                <button
                  onClick={() => {
                    dispatch(setVoteModalVisible())
                    dispatch(setVoteModalFail())
                  }}
                >
                  CLOSE
                </button>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  )
}

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import './sass/main.scss'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { NetworkContextName } from './constants'
import { Web3Provider } from '@ethersproject/providers'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import UserContext from './components/Profile/userContext'
import TxLoadingContext from './contexts/txLoadingContext'
import App from './App'
import {
  metamaskWallet,
  ThirdwebProvider,
  walletConnect,
  phantomWallet,
  coinbaseWallet,
  trustWallet,
} from '@thirdweb-dev/react'
import 'animate.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Polygon } from '@thirdweb-dev/chains'

toast.configure({ theme: 'colored' })

function getLibrary(provider: any) {
  if (provider?._isProvider) {
    provider.pollingInterval = 3000
    return provider
  }
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 3000
  return library
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

ReactDOM.render(
  <TxLoadingContext>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <BrowserRouter>
            <UserContext>
              <Updaters />
              <ThirdwebProvider
                supportedChains={[Polygon]}
                supportedWallets={[
                  metamaskWallet(),
                  coinbaseWallet({
                    qrmodal: 'custom',
                  }),
                  walletConnect({
                    projectId: process.env.REACT_APP_PROJECT_ID,
                    qrModal: 'custom',
                    qrModalOptions: {
                      themeMode: 'dark',
                    },
                  }),
                  phantomWallet(),
                  trustWallet(),

                ]}
                activeChain="polygon"
                autoConnect={true}
                clientId={process.env.REACT_APP_THIRDWEB_CI}
              >
                <App />
              </ThirdwebProvider>
            </UserContext>
          </BrowserRouter>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </TxLoadingContext>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import {
  CLOCKWORK_CHEST,
  DAO_ADDRESS,
  DEFAULT_CHAIN_ID,
  ERC1155_CONTRACT_ADDRESS,
  KNIGHT_CAT,
  LOCKER_ADDRESS,
  MARKET_PLACE_ADDRESS,
  MERGER_ADDRESS,
  SPECIAL_MERGER_ADDRESS,
  STEAMPUNK_CHEST,
  Transfer_Helper,
} from '../constants'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import NFT from '../constants/abis/NFT.json'
import MARKET_PLACE_ABI from '../abis/NFTSale.json'
import MERGE_ABI from '../abis/mergeABI.json'
import SPECIAL_MERGE_ABI from '../abis/specialMergeAbi.json'
import LOCKER_ABI from '../abis/lockerABI.json'
import DAO_ABI from '../abis/DAOABI.json'
import TRANSFER_HELPER_ABI from '../abis/TransferHelperABI.json'
import STEAMPUNKABI from '../abis/SteampunkABI.json'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'

import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import { steampunkChest } from 'assets'

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId
      ? MULTICALL_NETWORKS[chainId]
      : MULTICALL_NETWORKS[DEFAULT_CHAIN_ID],
    MULTICALL_ABI,
    false,
  )
}

export function useMarketPlaceContract(): Contract | null {
  return useContract(MARKET_PLACE_ADDRESS, MARKET_PLACE_ABI, true)
}

export function useMergeContract(): Contract | null {
  return useContract(MERGER_ADDRESS, MERGE_ABI, true)
}

export function useSpecialMergeContract(): Contract | null {
  return useContract(SPECIAL_MERGER_ADDRESS, SPECIAL_MERGE_ABI, true)
}

export function useLockerContract(): Contract | null {
  return useContract(LOCKER_ADDRESS, LOCKER_ABI, true)
}
export function useDAOContract(): Contract | null {
  return useContract(DAO_ADDRESS, DAO_ABI, true)
}

export function useSteampunkChestContract(): Contract | null {
  return useContract(STEAMPUNK_CHEST, STEAMPUNKABI, true)
}

export function useClockworkChestContract(): Contract | null {
  return useContract(CLOCKWORK_CHEST, STEAMPUNKABI, true)
}

export function useTransferHelperContract(): Contract | null {
  return useContract(Transfer_Helper, TRANSFER_HELPER_ABI, true)
}

export function useNFTContractObject(
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(ERC1155_CONTRACT_ADDRESS, NFT, withSignerIfPossible)
}

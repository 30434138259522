import Modal from '../components/common/Modal'
import { useSelector, useDispatch } from 'react-redux'
import {
  toggleCancelAuctionModal,
  toggleWithDrawModal,
} from '../redux/slices/modalSlice'
import CancelAuctionModal from '../components/modalComponents/CancelAuctionModal'
import WithdrawModal from '../components/modalComponents/WithdrawModal'

/**
 * @returns the modal component renderer for the modals
 */

const AllModals = () => {
  const { cancelAuctionModal, withdrawModal } = useSelector(
    (state: any) => state.modalState,
  )
  const dispatch = useDispatch()
  return (
    <div>
      {cancelAuctionModal && (
        <Modal
          content={<CancelAuctionModal />}
          triggerModalClose={() => dispatch(toggleCancelAuctionModal({}))}
        />
      )}
      {withdrawModal && (
        <Modal
          content={<WithdrawModal />}
          triggerModalClose={() => dispatch(toggleWithDrawModal({}))}
        />
      )}
    </div>
  )
}

export default AllModals

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from '../../redux/store'
import style from '../../styles/cardComponents/cancelAuctionModal.module.scss'
import {
  toggleWithDrawModal,
} from 'redux/slices/modalSlice'
import usePersistentToast from 'hooks/usePersistentToast'
import { triggerNFTReload } from '../../redux/slices/nftSlice'
import useNFT from '../../hooks/useNFT'
import { useTxLoadingContext } from '../../contexts/txLoadingContext'
import useMarketPlaceContract from 'hooks/Thirdweb/useMArketPlaceContract'

/**
 *
 * @returns the cancel auction modal component
 */

const WithdrawModal: React.FC = () => {
  const { setTxLoading } = useTxLoadingContext()
  // consuming the redux state for getting the destructured data

  const {
    modalState: { modalInfo },
  } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const { finishAuction, cancelBid } = useMarketPlaceContract()
  // @ts-ignore
  const currentData: any = useNFT(modalInfo.NFTID.toString())

  /**
   * @description custom toast config for cancel in progress
   */

  const cancelInProgressToast = usePersistentToast(
    'Transaction in progress',
    'info',
  )

  /**
   * @description method for initiating the cancel process for auction for NFT
   */

  const submitHandler = async () => {
    cancelInProgressToast.trigger()
    setTxLoading(true)
    //NFTid is the id of sale:)
    let tx
    if (modalInfo['isBidCancel']) {
      tx = await cancelBid(Number(modalInfo['saleID']))
    } else {
      tx = await finishAuction(Number(modalInfo['saleID']))
    }
    if (typeof tx !== 'string') {
      cancelInProgressToast.dismiss()
      setTxLoading(false)
      dispatch(toggleWithDrawModal({}))
      if (modalInfo['isBidCancel']) {
        toast.success('Bid cancelled Successfully!')
      } else if (modalInfo['isFinish']) {
        toast.success('Auction finished Successfully!')
      } else toast.success('NFT Withdrawn Successfully!')

      dispatch(triggerNFTReload())
      window.location.reload()
    } else {
      cancelInProgressToast.dismiss()
      setTxLoading(false)
      dispatch(toggleWithDrawModal({}))
      if (modalInfo['isBidCancel']) {
        toast.success('Bid cancelled Failed!')
      } else if (modalInfo['isFinish']) {
        toast.success('Auction finished Failed!')
      } else toast.success('NFT Withdrawn Failed!')
      dispatch(triggerNFTReload())

      //
      // dispatch(toggleListingModalError('Please try again!'))
      // dispatch(toggleListingModal(currentData.tokenId))
      // dispatch(
      //   toggleListingModalAfter({
      //     NFTid: currentData.tokenId.toString(),
      //     tokenId: currentData.tokenId.toString(),
      //     NFTname: `${currentData.category} #${currentData.tokenId}`,
      //   }),
      // )
    }
  }
  return (
    <div className={style.modalContainer}>
      <img
        src={currentData?.image}
        className={style.cardImage}
        alt={'User Profile Page'}
      />
      <h2 className={style.productName}>
        {' '}
        {currentData?.category} #{currentData?.tokenId}
      </h2>
      <div className={style.purchaseState}>
        {modalInfo['isFinish']
          ? 'You can Finish the Auction and get your Knight or NFT back!'
          : modalInfo['isBidCancel']
          ? 'You can cancel your bid and get your Knight token back(2.5% penalty)!'
          : 'You can List your NFT again, after you withdraw your NFT!'}
      </div>
      <div className={style.actionButtonContainer}>
        <div className={style.actionButtonGreen} onClick={submitHandler}>
          {modalInfo['isFinish']
            ? 'Finish Auction'
            : modalInfo['isBidCancel']
            ? 'Cancel Bid'
            : 'Withdraw NFT'}
        </div>
        <div
          style={{ backgroundColor: 'grey', border: 'none' }}
          className={style.actionButtonGreen}
          onClick={() => dispatch(toggleWithDrawModal({}))}
        >
          {/* {modalInfo['isFinish'] ? 'Finish Auction' : modalInfo['isBidCancel'] ? 'Cancel Bid' : 'Withdraw NFT' } */}
          Abort
        </div>
      </div>
    </div>
  )
}

export default WithdrawModal

import {
  LogLevel,
  ReservoirWallet,
  getClient,
} from '@reservoir0x/reservoir-sdk'
import { arrayify } from 'ethers/lib/utils'
import { TypedDataSigner } from '@ethersproject/abstract-signer/lib/index'
import { getAddress, hexToBigInt, http } from 'viem'
import { useSDK } from '@thirdweb-dev/react'

export const useEthersAdapter = (): ReservoirWallet => {
  const thirdWebSdk = useSDK()
  const signer = thirdWebSdk?.getSigner()

  // Function to fetch address asynchronously
  const getAddresss = async () => {
    try {
      const addr = getAddress(await thirdWebSdk?.getSigner()?.getAddress()!)
      return addr // Assuming only one account is connected
    } catch (error) {
      console.error('Error fetching address:', error)
      throw error // Rethrow to ensure error handling in calling functions
    }
  }

  return {
    transport: http(),
    address: getAddresss, // Return the async function to fetch address
    handleSignMessageStep: async (stepItem) => {
      const client = getClient()
      const signData = stepItem.data?.sign
      let signature: string | undefined
      try {
        if (signData) {
          // Request user signature
          if (signData.signatureKind === 'eip191') {
            client.log(['Execute Steps: Signing with eip191'], LogLevel.Verbose)
            if (signData.message.match(/0x[0-9a-fA-F]{64}/)) {
              // If the message represents a hash, we need to convert it to raw bytes first
              signature = await signer.signMessage(arrayify(signData.message))
            } else {
              signature = await signer.signMessage(signData.message)
            }
          } else if (signData.signatureKind === 'eip712') {
            client.log(['Execute Steps: Signing with eip712'], LogLevel.Verbose)
            signature = await (
              signer as unknown as TypedDataSigner
            )._signTypedData(signData.domain, signData.types, signData.value)
          }
        }
        return signature
      } catch (error) {
        console.error('Error signing message:', error)
      }
    },
    handleSendTransactionStep: async (chainId, stepItem) => {
      const stepData = stepItem.data
      const signer = thirdWebSdk?.getSigner()
      try {
        const transaction = await signer.sendTransaction({
          chainId: 137, // Explicitly set the chain ID to Polygon
          data: stepData.data,
          to: stepData.to,
          from: stepData.from,
          value: hexToBigInt((stepData.value as any) || 0),
          ...(stepData.maxFeePerGas && {
            maxFeePerGas: hexToBigInt(stepData.maxFeePerGas as any),
          }),
          ...(stepData.maxPriorityFeePerGas && {
            maxPriorityFeePerGas: hexToBigInt(
              stepData.maxPriorityFeePerGas as any,
            ),
          }),
          ...(stepData.gas && {
            gasLimit: hexToBigInt(stepData.gas as any),
          }),
        })

        return transaction.hash as `0x${string}`
      } catch (error) {
        console.error('Error sending transaction:', error)
      }
    },
  }
}

import { ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { BigNumber } from '@ethersproject/bignumber'
import { Fraction } from '@sushiswap/sdk'

export const formatBalance = (
  value: ethers.BigNumberish,
  decimals = 18,
  maxFraction = 0,
) => {
  const formatted = ethers.utils.formatUnits(value, decimals)
  if (maxFraction > 0) {
    const split = formatted.split('.')
    if (split.length > 1) {
      return split[0] + '.' + split[1].substr(0, maxFraction)
    }
  }
  return formatted
}
export const formatToBalance = (value: string | undefined, decimals = 18) => {
  if (value) {
    return { value: ethers.utils.parseUnits(Number(value).toFixed(decimals), decimals), decimals: decimals }
  } else {
    return { value: BigNumber.from(0), decimals: decimals }
  }
}

export const formatFromBalance = (value: BigNumber | undefined, decimals = 18): Fraction => {
  if (value) {
    return new Fraction(value.toString(), BigNumber.from(10).pow(decimals).toString())
  }
  return new Fraction("0", "1")
}
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export const parseBalance = (value: string, decimals = 18) => {
  return ethers.utils.parseUnits(value || '0', decimals)
}

export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

export const isEmptyValue = (text: string) =>
  ethers.BigNumber.isBigNumber(text)
    ? ethers.BigNumber.from(text).isZero()
    : text === '' || text.replace(/0/g, '').replace(/\./, '') === ''


export const dateCalculatorUtils = (date: string|number) => {
  let date1
  if (typeof date === 'string') {
    date1 = new Date(parseInt(date) * 1000)
  }else {
    date1 = new Date(date * 1000)
  }
  if(!date)
    return ''
  const date2 = new Date()
  let diffTime = date2.getTime() - date1.getTime()
  diffTime = diffTime / (24 * 60 * 60 * 1000)
  if (diffTime < 1) {
    return 'Today'
  } else if (diffTime < 30) {
    return `${Math.round(diffTime)} days ago`
  } else if (diffTime < 365) {
    return `${Math.round(diffTime / 30)} months ago`
  }else if (diffTime < 1365) {
    return `${Math.round(diffTime / 30)} months ago`
  } else {
    return "Long time ago"
  }
}
import { CartPopover } from '@reservoir0x/reservoir-kit-ui'
import { useAddress } from '@thirdweb-dev/react'
import { useReservoir } from 'hooks/Reservoir'
import { useState } from 'react'
import { FaShoppingCart } from 'react-icons/fa'

export default function Cart() {
  const address = useAddress()
  const [open, setOpen] = useState(false)
  const { useCartHooks } = useReservoir()
  const { data } = useCartHooks()

  return (
    <>
      {address && (
        <CartPopover
          trigger={
            <button
              style={{
                height: 45,
                width: 45,
                borderRadius: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 50,
                border: `2px solid ${
                  data.items.length > 0 ? '#eda327' : '#1e9f5f'
                }`,
                background: 'unset',
                position: 'relative',
              }}
            >
              {data.items.length > 0 && (
                <div
                  style={{
                    height: 20,
                    width: 20,
                    padding: 1,
                    backgroundColor: 'rebeccapurple',
                    position: 'absolute',
                    top: -7,
                    right: -7,
                    borderRadius: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontWeight: 700,
                    fontSize: 12,
                  }}
                >
                  {data.items.length}
                </div>
              )}
              <FaShoppingCart
                style={{
                  height: 20,
                  width: 20,
                  color: data.items.length > 0 ? '#eda327' : '#1e9f5f',
                }}
              />
            </button>
          }
          key={'cart key'}
          side={'top'}
          openState={[open, setOpen]}
          onConnectWallet={() => {}}
        />
      )}
    </>
  )
}

import React from 'react'
import {
  ForestLogo,
  GooglePlayStore,
  gateLogo,
  quickSwapLogo,
} from '../../assets/index'
import '../../styles/common/Footer.scss'
import { useHistory } from 'react-router'
import { isMobile } from 'react-device-detect'

/**
 *
 * @returns the footer UI-component
 */

const JoinCommunity = (): JSX.Element => (
  <div className="join-community">
    <p className="join-community__header">Join Our Community:</p>
    <div className="join-community__icons">
      <a
        className="icon icon--discord"
        href="https://discord.com/invite/forest-knight-511929151272386561"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 640 512">
          <path d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z" />
        </svg>
      </a>
      <a
        className="icon icon--facebook"
        href="https://www.facebook.com/ForestKnight.io/"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 512 512">
          <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
        </svg>
      </a>
      <a
        className="icon icon--medium"
        href="https://medium.com/forest-knight"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 640 512">
          <path d="M180.5 74.3C80.8 74.3 0 155.6 0 256S80.8 437.7 180.5 437.7 361 356.4 361 256 280.2 74.3 180.5 74.3zm288.3 10.6c-49.8 0-90.2 76.6-90.2 171.1s40.4 171.1 90.3 171.1 90.3-76.6 90.3-171.1H559C559 161.5 518.6 84.9 468.8 84.9zm139.5 17.8c-17.5 0-31.7 68.6-31.7 153.3s14.2 153.3 31.7 153.3S640 340.6 640 256C640 171.4 625.8 102.7 608.3 102.7z" />
        </svg>
      </a>
      <a
        className="icon icon--twitter"
        href="https://twitter.com/forestknight_io?lang=en"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 512 512">
          <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
        </svg>
      </a>
      <a
        className="icon icon--youtube"
        href="https://www.youtube.com/channel/UC1w_--ydj35pOgFxiVS1zYQ"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 576 512">
          <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
        </svg>
      </a>
      <a
        className="icon icon--instagram"
        href="https://www.instagram.com/forestknightofficial/"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 448 512">
          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
        </svg>
      </a>
      <a
        className="icon icon--telegram"
        href="https://t.me/Forestknight"
        target="_blank"
        rel="noreferrer"
      >
        <svg className="icon__body" viewBox="0 0 496 512">
          <path d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z" />
        </svg>
      </a>
    </div>
  </div>
)

const Footer: React.FC = () => {
  const history = useHistory()

  return (
    <section className="footer-section">
      <div className="footer-top">
        <div className="footer-left-top">
          <img src={ForestLogo} alt="footer-img" />
          <p>
            Forest Knight is a turn-based strategy mobile game that allows
            players to build their dream team and go on an adventure through the
            eyes of the Forest Knight. By using blockchain technology, you will
            acquire unique NFTs which you can equip your heroes with or trade
            with other players.
          </p>
        </div>

        {!isMobile && (
          <div className="footer-left-middle">
            <a
              className="play-store-btn"
              href="https://play.google.com/store/apps/details?id=com.ChronoGames.Forest.Knight&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <img src={GooglePlayStore} alt="Google Play Store" />
            </a>
            <a
              className="pc-btn"
              href="http://medium.com/forest-knight/how-to-run-forest-knight-on-pc-d9fc557a2772"
              target="_blank"
              rel="noreferrer"
            >
              Play On PC
            </a>
          </div>
        )}

        <div className="footer-left-bottom">
          <span className="buy-knight-text">Buy $KNIGHT:</span>
          <div className="quick-gate">
            <a
              className="button button--quick-swap"
              href="https://quickswap.exchange/#/swap?outputCurrency=0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="button__logo"
                src={quickSwapLogo}
                alt="quick swap logo"
              />
            </a>
            <a
              className="button button--gate-io"
              href="https://www.gate.io/trade/KNIGHT_USDT"
              target="_blank"
              rel="noreferrer"
            >
              <img className="button__logo" src={gateLogo} alt="gate io logo" />
            </a>
          </div>
        </div>

        <div className="footer-right-top">
          <ul>
            <li onClick={() => history.push('/')}>
              <span className="footer-header-list">Navigation</span>
            </li>
            <li>
              <a
                href="/marketplace-v2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.75 11.25H4.5V6.875H2.75V13.875C2.75 14.3672 3.13281 14.75 3.625 14.75H10.625C11.0898 14.75 11.5 14.3672 11.5 13.875V6.875H9.75V11.25ZM18.3359 4.66016L16.0117 1.16016C15.8477 0.914062 15.5742 0.75 15.2734 0.75H4.19922C3.89844 0.75 3.625 0.914062 3.46094 1.16016L1.13672 4.66016C0.753906 5.23438 1.16406 6 1.875 6H17.625C18.3086 6 18.7188 5.23438 18.3359 4.66016ZM15 14.3125C15 14.5586 15.1914 14.75 15.4375 14.75H16.3125C16.5312 14.75 16.75 14.5586 16.75 14.3125V6.875H15V14.3125Z"
                    fill="white"
                  />
                </svg>
                <span>MarketPlace</span>
              </a>
            </li>
            <li>
              <a
                href="/profile-v2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.78125 0.96875C3.03516 0.96875 0 4.00391 0 7.75C0 11.4961 3.03516 14.5312 6.78125 14.5312C10.5273 14.5312 13.5625 11.4961 13.5625 7.75C13.5625 4.00391 10.5273 0.96875 6.78125 0.96875ZM6.78125 3.59375C8.09375 3.59375 9.1875 4.6875 9.1875 6C9.1875 7.33984 8.09375 8.40625 6.78125 8.40625C5.44141 8.40625 4.375 7.33984 4.375 6C4.375 4.6875 5.44141 3.59375 6.78125 3.59375ZM6.78125 13C5.16797 13 3.71875 12.2891 2.76172 11.1406C3.28125 10.1836 4.29297 9.5 5.46875 9.5C5.52344 9.5 5.57812 9.52734 5.66016 9.55469C6.01562 9.66406 6.37109 9.71875 6.78125 9.71875C7.16406 9.71875 7.54688 9.66406 7.875 9.55469C7.95703 9.52734 8.01172 9.5 8.09375 9.5C9.24219 9.5 10.2539 10.1836 10.7734 11.1406C9.81641 12.2891 8.36719 13 6.78125 13Z"
                    fill="white"
                  />
                </svg>
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a
                href="/dao"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 12.6875V14C0 15.125 2.6875 16 6 16C9.3125 16 12 15.125 12 14V12.6875C10.6875 13.5938 8.34375 14 6 14C3.625 14 1.28125 13.5938 0 12.6875ZM10 4C13.3125 4 16 3.125 16 2C16 0.90625 13.3125 0 10 0C6.6875 0 4 0.90625 4 2C4 3.125 6.6875 4 10 4ZM0 9.40625V11C0 12.125 2.6875 13 6 13C9.3125 13 12 12.125 12 11V9.40625C10.6875 10.4688 8.34375 11 6 11C3.625 11 1.28125 10.4688 0 9.40625ZM13 9.75C14.7812 9.40625 16 8.75 16 8V6.6875C15.25 7.1875 14.1875 7.53125 13 7.75V9.75ZM6 5C2.6875 5 0 6.125 0 7.5C0 8.90625 2.6875 10 6 10C9.3125 10 12 8.90625 12 7.5C12 6.125 9.3125 5 6 5ZM12.8438 6.78125C14.7188 6.4375 16 5.78125 16 5V3.6875C14.875 4.46875 12.9688 4.875 10.9688 5C11.875 5.4375 12.5625 6.03125 12.8438 6.78125Z"
                    fill="white"
                  ></path>
                </svg>
                <span>DAO</span>
              </a>
            </li>
            <li>
              <a
                href="/merge"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <svg
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6133 6.05469L15.9844 5.45312C15.8203 5.28906 15.5469 5.28906 15.3828 5.45312L15.0547 5.75391L14.2617 4.96094C14.4258 4.38672 14.2891 3.73047 13.8242 3.29297L12.5938 2.03516C10.8711 0.339844 8.10938 0.339844 6.38672 2.03516L8.875 3.29297V3.78516C8.875 4.25 9.06641 4.71484 9.39453 5.04297L10.7344 6.38281C11.1992 6.82031 11.8281 6.98438 12.4023 6.82031L13.1953 7.61328L12.8945 7.91406C12.7305 8.10547 12.7305 8.37891 12.8945 8.54297L13.5234 9.17188C13.6875 9.33594 13.9609 9.33594 14.125 9.17188L16.6133 6.68359C16.7773 6.51953 16.7773 6.24609 16.6133 6.05469ZM8.76562 5.64453C8.65625 5.5625 8.57422 5.42578 8.49219 5.31641L1.51953 11.8242C0.835938 12.4805 0.808594 13.6016 1.49219 14.2578C2.14844 14.9414 3.26953 14.9141 3.92578 14.2305L10.4336 7.25781C10.3242 7.17578 10.2148 7.09375 10.1055 6.98438L8.76562 5.64453Z"
                    fill="#fff"
                  ></path>
                </svg>
                <span>Merge</span>
              </a>
            </li>
            <li>
              <a
                href="/forge"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  role="img"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18.326 9.2145S23.2261 8.4418 24 6.1882h-7.5066V4.4H0l2.0318 2.3576V9.173s5.1267-.2665 7.1098 1.2372c2.7146 2.516-3.053 5.917-3.053 5.917L5.0995 19.6c1.5465-1.4726 4.494-3.3775 9.8983-3.2857-2.0565.65-4.1245 1.6651-5.7344 3.2857h10.9248l-1.0288-3.2726s-7.918-4.6688-.8336-7.1127z"></path>
                </svg>
                <span>Forge</span>
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="/" className="footer-header-list">
                About
              </a>
            </li>
            <li>
              <a href="https://drive.google.com/file/d/1ON7PJEvFOg-ci1XBaBLP9NCbIcBW6j5C/view">
                KnightPaper
              </a>
            </li>
            <li>
              <a href="https://forestknight.io/download">Download</a>
            </li>
            <li>
              <a href="https://forest-knight.gitbook.io/forest-knight-guide/">
                Guide
              </a>
            </li>
            <li>
              <a href="/">Token</a>
            </li>
            <li>
              <a href="https://www.forestknight.io/knight">$KNIGHT</a>
            </li>
            <li>
              <a href="/profile">Assets</a>
            </li>
            <li>
              <a href="https://chronogames.atlassian.net/servicedesk/customer/portals">
                Contact us
              </a>
            </li>
            <li>
              <a href="https://www.forestknight.io/faq">FAQ</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="/" className="footer-header-list">
                Marketplace
              </a>
            </li>
            <li>
              <a
                href="https://forest-knight.gitbook.io/forest-knight-guide/nft-marketplace"
                target={'_blank'}
              >
                How it works
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="/" className="footer-header-list">
                Info
              </a>
            </li>
            <li>
              <a href="https://www.forestknight.io/terms-of-use">
                Terms of Use
              </a>
            </li>
            <li>
              <a href="https://www.forestknight.io/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://polygonscan.com/token/0x4455ef8b4b4a007a93daa12de63a47eeac700d9d">
                Contract
              </a>
            </li>
          </ul>
        </div>
        <div className="rectangle-img">
          <a href="#header">
            <svg
              width="11"
              height="7"
              viewBox="0 0 11 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="black-arrow"
            >
              <path
                d="M4.85156 6.45312C5.0625 6.66406 5.41406 6.66406 5.625 6.45312L10.1953 1.90625C10.4062 1.67188 10.4062 1.32031 10.1953 1.10938L9.65625 0.570312C9.44531 0.359375 9.09375 0.359375 8.85938 0.570312L5.25 4.17969L1.61719 0.570312C1.38281 0.359375 1.03125 0.359375 0.820312 0.570312L0.28125 1.10938C0.0703125 1.32031 0.0703125 1.67188 0.28125 1.90625L4.85156 6.45312Z"
                fill="black"
              />
            </svg>
            <svg
              width="67"
              height="67"
              viewBox="0 0 67 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="circle"
            >
              <rect
                width="66.6667"
                height="66.6667"
                rx="33.3333"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="33.3333"
                  y1="0"
                  x2="33.3333"
                  y2="66.6667"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFC665" />
                  <stop offset="1" stopColor="#FFAC20" />
                </linearGradient>
              </defs>
            </svg>
          </a>
        </div>
        <div className="footer-right-bottom">
          <JoinCommunity />
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copy-right-text">
          © Copyright 2024 Forest Knight. All rights reserved.
        </p>
      </div>
    </section>
  )
}

export default Footer

import { useCallback } from 'react'
import { useTransactionAdder } from '../../state/transactions/hooks'
import web3 from 'web3'
import { toWei, useContract, useSDK } from '@thirdweb-dev/react'
import { MARKET_PLACE_ADDRESS } from 'constants/index'
import MARKET_PLACE_ABI from 'abis/NFTSale.json'

export default function useMarketPlaceContract() {
  const sdk = useSDK()
  const { contract: marketLlaceContract } = useContract(
    MARKET_PLACE_ADDRESS,
    MARKET_PLACE_ABI,
  )
  const addTransaction = useTransactionAdder()

  /**
   * @description This function creates a fixed price NFT sale
   * @param tokenId The id of the NFT willing to sale
   * @param price The price of the selling item
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const createSale = useCallback(
    async (tokenID: number, price: string) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [tokenID, price]
        const tx = await contract
          .call('createSale', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return 'error'

        addTransaction(tx.receipt, {
          summary: `List NFT ${tokenID} for ${
            Number(price) * 1e-18
          } Knight token`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function creates a NFT auction sale
   * @param tokenId The id of the NFT willing to sale
   * @param startingPrice The starting price of the selling item
   * @param endPrice The Buy noe price of the auction if user decided to sale with buy now option
   * @param startTime The starting time of the selling item
   * @param length The duration of listing
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const createAuction = useCallback(
    async (
      tokenID: number,
      startingPrice: string,
      endPrice: string,
      startTime: number,
      length: number,
    ) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [tokenID, startingPrice, endPrice, startTime, length]
        const tx = await contract
          .call('createAuction', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"

        addTransaction(tx.receipt, {
          summary: `List NFT ${tokenID} for Auction`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function cancels a NFT listing
   * @param saleId The id of the listing
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const cancelSale = useCallback(
    async (saleId: number) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [saleId]
        const tx = await contract
          .call('cancelSale', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"

        addTransaction(tx.receipt, { summary: `Sale is Cancelled` })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function calims the NFT back from MarketPlace contract
   * @param saleId The id of the listing
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const finishAuction = useCallback(
    async (saleId: number) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [saleId]
        const tx = await contract
          .call('finishAuction', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"

        addTransaction(tx.receipt, {
          summary: `NFT is claimed back from Market Place`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function transfers the NFT listed to the buyer
   * @param saleId The id of the listing
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const buyNFT = useCallback(
    async (saleId: number) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [saleId]
        const tx = await contract
          .call('buy', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"

        addTransaction(tx.receipt, {
          summary: `NFT is Bought`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function handles bidding on auctions
   * @param saleId The id of the listing
   * @param bidAmount The amount that user willing to bid on sale
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const bid = useCallback(
    async (saleId: number, bidAmount: string) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [saleId, toWei(bidAmount)]
        const tx = await contract
          .call('bidAuction', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"

        addTransaction(tx.receipt, {
          summary: `Bidded on sale id: ${saleId}`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function handles increasing bid on auctions
   * @param saleId The id of the listing
   * @param bidAmount The amount that user willing to increase
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const increaseBid = useCallback(
    async (saleId: number, bidAmount: string) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [saleId, toWei(bidAmount)]
        const tx = await contract
          .call('increaseBid', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"

        addTransaction(tx.receipt, {
          summary: `Bid increased on sale id: ${saleId}`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  /**
   * @description This function cancels user's bid on an auction listing
   * @param saleId The id of the listing
   * @returns If the operation was successful it returns tx receipt and if fails returns "error"
   */
  const cancelBid = useCallback(
    async (saleId: number) => {
      try {
        const contract = await sdk.getContract(MARKET_PLACE_ADDRESS)
        const args = [saleId]
        const tx = await contract
          .call('cancelBid', args)
          .then((res) => res)
          .catch((err) => {
            console.error(err)
            return 'error'
          })

        if (typeof tx === 'string') return "error"
        
        addTransaction(tx.receipt, {
          summary: `Bid is Canceled`,
        })
        return tx.receipt
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    [sdk],
  )

  return {
    createSale,
    createAuction,
    cancelSale,
    finishAuction,
    buyNFT,
    bid,
    increaseBid,
    cancelBid,
  }
}

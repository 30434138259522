// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_ModalContainer__BwMLT {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.Modal_ModalContainer__BwMLT ::-webkit-scrollbar {
  width: 8px;
}
.Modal_ModalContainer__BwMLT ::-webkit-scrollbar-track {
  background: #C4C4C4;
}
.Modal_ModalContainer__BwMLT ::-webkit-scrollbar-thumb {
  background: #FFC665;
}
.Modal_ModalContainer__BwMLT .Modal_ModalBackground__pfW-9 {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
}
.Modal_ModalContainer__BwMLT .Modal_ModalContent__ekHZD {
  padding: 45px 5px;
  position: relative;
  margin: auto;
  min-height: 20vh;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-width: 100vw;
  width: 809px;
  background-size: 90% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.Modal_ModalContainer__BwMLT .Modal_ModalContent__ekHZD::-webkit-scrollbar {
  display: none;
}
.Modal_ModalContainer__BwMLT .Modal_ModalContentContainer__IvbYB {
  max-height: 80vh;
  overflow-y: auto;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,UAAA;AACJ;AACE;EACE,mBAAA;AACJ;AACE;EACE,mBAAA;AACJ;AAEE;EACE,eAAA;EACA,aAAA;EACA,YAAA;EACA,oCAAA;AAAJ;AAGE;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,4BAAA;EAAA,uBAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,2BAAA;EACA,4BAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,gBAAA;EACA,gBAAA;EACA,UAAA;AAHJ","sourcesContent":[".ModalContainer {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  z-index: 100;\n\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n  ::-webkit-scrollbar-track {\n    background: #C4C4C4;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #FFC665;\n  }\n\n  .ModalBackground {\n    position: fixed;\n    height: 100vh;\n    width: 100vw;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n\n  .ModalContent {\n    padding: 45px 5px;\n    position: relative;\n    margin: auto;\n    min-height: 20vh;\n    max-height: fit-content;\n    max-width: 100vw;\n    width: 809px;\n    background-size: 90% 100%;\n    background-position: center;\n    background-repeat: no-repeat;\n  }\n\n  .ModalContent::-webkit-scrollbar {\n    display: none;\n  }\n\n  .ModalContentContainer {\n    max-height: 80vh;\n    overflow-y: auto;\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalContainer": `Modal_ModalContainer__BwMLT`,
	"ModalBackground": `Modal_ModalBackground__pfW-9`,
	"ModalContent": `Modal_ModalContent__ekHZD`,
	"ModalContentContainer": `Modal_ModalContentContainer__IvbYB`
};
export default ___CSS_LOADER_EXPORT___;

import { gateLogoSmall, quickSwapLogoSmall, kriptomat } from '../assets/index'
import { PiTreasureChestFill } from 'react-icons/pi'
import { SiCurseforge } from "react-icons/si";


export type NavType = {
  label: string
  link: {
    href?: string
    pathname?: string
  }
  icon: ({ fill }: { fill?: string }) => JSX.Element
  subMenus?: {
    label: string
    link?: {
      pathname?: string
      href?: string
    }
    soon?: true
    icon: ({ fill }: { fill?: string }) => JSX.Element
  }[]
}

export const navArray: NavType[] = [
  // Marketplace
  {
    label: 'Marketplace',
    link: { pathname: '#' },
    icon: function Icon({ fill }: { fill?: string }) {
      return (
        <svg
          width="19"
          height="15"
          viewBox="0 0 19 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="marketplaceSmallLogo"
        >
          <path
            d="M9.75 11.25H4.5V6.875H2.75V13.875C2.75 14.3672 3.13281 14.75 3.625 14.75H10.625C11.0898 14.75 11.5 14.3672 11.5 13.875V6.875H9.75V11.25ZM18.3359 4.66016L16.0117 1.16016C15.8477 0.914062 15.5742 0.75 15.2734 0.75H4.19922C3.89844 0.75 3.625 0.914062 3.46094 1.16016L1.13672 4.66016C0.753906 5.23438 1.16406 6 1.875 6H17.625C18.3086 6 18.7188 5.23438 18.3359 4.66016ZM15 14.3125C15 14.5586 15.1914 14.75 15.4375 14.75H16.3125C16.5312 14.75 16.75 14.5586 16.75 14.3125V6.875H15V14.3125Z"
            fill="white"
          />
        </svg>
      )
    },
    subMenus: [
      {
        label: 'Marketplace V2',
        link: { pathname: '/' },
        icon: function Icon({ fill }: { fill?: string }) {
          return (
            <svg
              width="19"
              height="15"
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="marketplaceSmallLogo"
            >
              <path
                d="M9.75 11.25H4.5V6.875H2.75V13.875C2.75 14.3672 3.13281 14.75 3.625 14.75H10.625C11.0898 14.75 11.5 14.3672 11.5 13.875V6.875H9.75V11.25ZM18.3359 4.66016L16.0117 1.16016C15.8477 0.914062 15.5742 0.75 15.2734 0.75H4.19922C3.89844 0.75 3.625 0.914062 3.46094 1.16016L1.13672 4.66016C0.753906 5.23438 1.16406 6 1.875 6H17.625C18.3086 6 18.7188 5.23438 18.3359 4.66016ZM15 14.3125C15 14.5586 15.1914 14.75 15.4375 14.75H16.3125C16.5312 14.75 16.75 14.5586 16.75 14.3125V6.875H15V14.3125Z"
                fill="white"
              />
            </svg>
          )
        },
      },
      {
        label: 'Stats',
        soon: true,
        icon: function Icon({ fill }: { fill?: string }) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" />
            </svg>
          )
        },
      },
    ],
  },
  // DAO
  {
    label: 'DAO',
    link: { pathname: '/dao' },
    icon: function Icon({ fill }: { fill?: string }) {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="stakingSmallLogo"
        >
          <path
            d="M0 12.6875V14C0 15.125 2.6875 16 6 16C9.3125 16 12 15.125 12 14V12.6875C10.6875 13.5938 8.34375 14 6 14C3.625 14 1.28125 13.5938 0 12.6875ZM10 4C13.3125 4 16 3.125 16 2C16 0.90625 13.3125 0 10 0C6.6875 0 4 0.90625 4 2C4 3.125 6.6875 4 10 4ZM0 9.40625V11C0 12.125 2.6875 13 6 13C9.3125 13 12 12.125 12 11V9.40625C10.6875 10.4688 8.34375 11 6 11C3.625 11 1.28125 10.4688 0 9.40625ZM13 9.75C14.7812 9.40625 16 8.75 16 8V6.6875C15.25 7.1875 14.1875 7.53125 13 7.75V9.75ZM6 5C2.6875 5 0 6.125 0 7.5C0 8.90625 2.6875 10 6 10C9.3125 10 12 8.90625 12 7.5C12 6.125 9.3125 5 6 5ZM12.8438 6.78125C14.7188 6.4375 16 5.78125 16 5V3.6875C14.875 4.46875 12.9688 4.875 10.9688 5C11.875 5.4375 12.5625 6.03125 12.8438 6.78125Z"
            fill="white"
          />
        </svg>
      )
    },
  },
  // Crafting
  {
    label: 'Crafting',
    link: { pathname: '#' },
    icon: function Icon({ fill }: { fill?: string }) {
      return (
        <svg
          width="17"
          height="14"
          viewBox="0 0 17 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="clawSmallLogo"
        >
          <path
            d="M16.6133 6.05469L15.9844 5.45312C15.8203 5.28906 15.5469 5.28906 15.3828 5.45312L15.0547 5.75391L14.2617 4.96094C14.4258 4.38672 14.2891 3.73047 13.8242 3.29297L12.5938 2.03516C10.8711 0.339844 8.10938 0.339844 6.38672 2.03516L8.875 3.29297V3.78516C8.875 4.25 9.06641 4.71484 9.39453 5.04297L10.7344 6.38281C11.1992 6.82031 11.8281 6.98438 12.4023 6.82031L13.1953 7.61328L12.8945 7.91406C12.7305 8.10547 12.7305 8.37891 12.8945 8.54297L13.5234 9.17188C13.6875 9.33594 13.9609 9.33594 14.125 9.17188L16.6133 6.68359C16.7773 6.51953 16.7773 6.24609 16.6133 6.05469ZM8.76562 5.64453C8.65625 5.5625 8.57422 5.42578 8.49219 5.31641L1.51953 11.8242C0.835938 12.4805 0.808594 13.6016 1.49219 14.2578C2.14844 14.9414 3.26953 14.9141 3.92578 14.2305L10.4336 7.25781C10.3242 7.17578 10.2148 7.09375 10.1055 6.98438L8.76562 5.64453Z"
            fill="#fff"
          />
        </svg>
      )
    },
    subMenus: [
      {
        label: 'Merge',
        link: {
          pathname: '/merge',
        },
        icon: function Icon({ fill }: { fill?: string }) {
          return (
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="clawSmallLogo"
            >
              <path
                d="M16.6133 6.05469L15.9844 5.45312C15.8203 5.28906 15.5469 5.28906 15.3828 5.45312L15.0547 5.75391L14.2617 4.96094C14.4258 4.38672 14.2891 3.73047 13.8242 3.29297L12.5938 2.03516C10.8711 0.339844 8.10938 0.339844 6.38672 2.03516L8.875 3.29297V3.78516C8.875 4.25 9.06641 4.71484 9.39453 5.04297L10.7344 6.38281C11.1992 6.82031 11.8281 6.98438 12.4023 6.82031L13.1953 7.61328L12.8945 7.91406C12.7305 8.10547 12.7305 8.37891 12.8945 8.54297L13.5234 9.17188C13.6875 9.33594 13.9609 9.33594 14.125 9.17188L16.6133 6.68359C16.7773 6.51953 16.7773 6.24609 16.6133 6.05469ZM8.76562 5.64453C8.65625 5.5625 8.57422 5.42578 8.49219 5.31641L1.51953 11.8242C0.835938 12.4805 0.808594 13.6016 1.49219 14.2578C2.14844 14.9414 3.26953 14.9141 3.92578 14.2305L10.4336 7.25781C10.3242 7.17578 10.2148 7.09375 10.1055 6.98438L8.76562 5.64453Z"
                fill="#fff"
              />
            </svg>
          )
        },
      },
      {
        label: 'Forge',
        link: {
          pathname: '/forge',
        },
        icon: function Icon({ fill }: { fill?: string }) {
          return (
            <SiCurseforge/>
          )
        },
      },
    ],
  },
  // Quest
  {
    label: 'Quest',
    link: { pathname: '/quest' },
    icon: function Icon({ fill }: { fill?: string }) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 25 25"
          fill="white"
          stroke="#000000"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M16.2 7.8l-2 6.3-6.4 2.1 2-6.3z" />
        </svg>
      )
    },
  },
  // Profile
  {
    label: 'Profile',
    link: { pathname: '#' },
    icon: function Icon({ fill }: { fill?: string }) {
      return (
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="profileSmallLogo"
        >
          <path
            d="M6.78125 0.96875C3.03516 0.96875 0 4.00391 0 7.75C0 11.4961 3.03516 14.5312 6.78125 14.5312C10.5273 14.5312 13.5625 11.4961 13.5625 7.75C13.5625 4.00391 10.5273 0.96875 6.78125 0.96875ZM6.78125 3.59375C8.09375 3.59375 9.1875 4.6875 9.1875 6C9.1875 7.33984 8.09375 8.40625 6.78125 8.40625C5.44141 8.40625 4.375 7.33984 4.375 6C4.375 4.6875 5.44141 3.59375 6.78125 3.59375ZM6.78125 13C5.16797 13 3.71875 12.2891 2.76172 11.1406C3.28125 10.1836 4.29297 9.5 5.46875 9.5C5.52344 9.5 5.57812 9.52734 5.66016 9.55469C6.01562 9.66406 6.37109 9.71875 6.78125 9.71875C7.16406 9.71875 7.54688 9.66406 7.875 9.55469C7.95703 9.52734 8.01172 9.5 8.09375 9.5C9.24219 9.5 10.2539 10.1836 10.7734 11.1406C9.81641 12.2891 8.36719 13 6.78125 13Z"
            fill="white"
          />
        </svg>
      )
    },
    subMenus: [
      {
        label: 'Profile V1',
        link: { pathname: '/profile-v1' },
        icon: function Icon({ fill }: { fill?: string }) {
          return (
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="profileSmallLogo"
            >
              <path
                d="M6.78125 0.96875C3.03516 0.96875 0 4.00391 0 7.75C0 11.4961 3.03516 14.5312 6.78125 14.5312C10.5273 14.5312 13.5625 11.4961 13.5625 7.75C13.5625 4.00391 10.5273 0.96875 6.78125 0.96875ZM6.78125 3.59375C8.09375 3.59375 9.1875 4.6875 9.1875 6C9.1875 7.33984 8.09375 8.40625 6.78125 8.40625C5.44141 8.40625 4.375 7.33984 4.375 6C4.375 4.6875 5.44141 3.59375 6.78125 3.59375ZM6.78125 13C5.16797 13 3.71875 12.2891 2.76172 11.1406C3.28125 10.1836 4.29297 9.5 5.46875 9.5C5.52344 9.5 5.57812 9.52734 5.66016 9.55469C6.01562 9.66406 6.37109 9.71875 6.78125 9.71875C7.16406 9.71875 7.54688 9.66406 7.875 9.55469C7.95703 9.52734 8.01172 9.5 8.09375 9.5C9.24219 9.5 10.2539 10.1836 10.7734 11.1406C9.81641 12.2891 8.36719 13 6.78125 13Z"
                fill="white"
              />
            </svg>
          )
        },
      },
      {
        label: 'Profile V2',
        link: { pathname: '/profile-v2' },
        icon: function Icon({ fill }: { fill?: string }) {
          return (
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="profileSmallLogo"
            >
              <path
                d="M6.78125 0.96875C3.03516 0.96875 0 4.00391 0 7.75C0 11.4961 3.03516 14.5312 6.78125 14.5312C10.5273 14.5312 13.5625 11.4961 13.5625 7.75C13.5625 4.00391 10.5273 0.96875 6.78125 0.96875ZM6.78125 3.59375C8.09375 3.59375 9.1875 4.6875 9.1875 6C9.1875 7.33984 8.09375 8.40625 6.78125 8.40625C5.44141 8.40625 4.375 7.33984 4.375 6C4.375 4.6875 5.44141 3.59375 6.78125 3.59375ZM6.78125 13C5.16797 13 3.71875 12.2891 2.76172 11.1406C3.28125 10.1836 4.29297 9.5 5.46875 9.5C5.52344 9.5 5.57812 9.52734 5.66016 9.55469C6.01562 9.66406 6.37109 9.71875 6.78125 9.71875C7.16406 9.71875 7.54688 9.66406 7.875 9.55469C7.95703 9.52734 8.01172 9.5 8.09375 9.5C9.24219 9.5 10.2539 10.1836 10.7734 11.1406C9.81641 12.2891 8.36719 13 6.78125 13Z"
                fill="white"
              />
            </svg>
          )
        },
      },
    ],
  },
  // Chests
  {
    label: 'Chests',
    link: { pathname: '#' },
    icon: function Icon({ fill }: { fill?: string }) {
      return <PiTreasureChestFill style={{color : "white"}} />
    },
    subMenus: [
      {
        label: 'Steampunk Chest',
        link: { pathname: '/chest-steampunk' },
        icon: function Icon({ fill }: { fill?: string }) {
          return <PiTreasureChestFill />
        },
      },
      {
        label: 'Clockwork Chest',
        link: { pathname: '/chest-clockwork' },
        icon: function Icon({ fill }: { fill?: string }) {
          return <PiTreasureChestFill />
        },
      },
    ],
  },
]

export const GET_KNIGHT_ITEMS: (
  | {
      href: string
      title: string
      icon: string
    }
  | {
      href: string
      title: string
      icon?: undefined
    }
)[] = [
  {
    href: 'https://quickswap.exchange/#/swap?outputCurrency=0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D',
    title: 'QuickSwap',
    icon: quickSwapLogoSmall,
  },
  {
    href: 'https://www.gate.io/trade/KNIGHT_USDT',
    title: 'Gate.io',
    icon: gateLogoSmall,
  },
  {
    href: 'https://kriptomat.io/web3/wallet/game/forest-knight?utm_source=forest-knight&utm_medium=gamefi-wallet&utm_campaign=generic',
    title: 'Kriptomat',
    icon: kriptomat,
  },
  {
    href: 'https://www.forestknight.io/knight',
    title: 'Learn more',
  },
]

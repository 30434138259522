import React from 'react'
import './ChestModals.scss' // Ensure this CSS file is structured to apply to the new modals as well
import { AppState } from '../../../state/index'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoadingModalVisible,
  setTransactionFailModalVisible,
  setTransactionSuccessModalVisible,
} from '../stakingSlices/ModalSlicesChest' // Adjust this import path to your project structure
import GoToDashboardButton from '../buttons/GoToDashboardButton' // Adjust this import path as necessary

export default function ChestBurnModals() {
  const modal = useSelector((state: AppState) => state.ChestBurnModal)

  return (
    <>
      {modal.loadingModalVisible && <LoadingModal />}
      {modal.transactionFailModalVisible && <TransactionFailModal />}
      {modal.transactionSuccessModalVisible && <TransactionSuccessModal />}
    </>
  )
}

// Loading Modal Component
const LoadingModal = () => {
  const dispatch = useDispatch()
  return (
    <div
      className="ClaimModal_wrapper"
      onClick={() => {
        dispatch(setLoadingModalVisible(true))
      }}
    >
      <section
        className="ClaimModal_container"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <>
          <div className="ClaimModal_container_title">
            <h1>Transaction pending</h1>
          </div>
          <div className="ClaimModal_container_loadingBar">
            <div />
          </div>
          <div className="ClaimModal_container_message">
            <p>Please confirm the transaction in your wallet</p>
          </div>
        </>
      </section>
    </div>
  )
}

// Transaction Failure Modal Component
const TransactionFailModal = () => {
  const dispatch = useDispatch()
  return (
    <div
      className="all_modals_wrapper"
      onClick={() => dispatch(setTransactionFailModalVisible(false))}
    >
      <section className="modals_container modals_container_fail">
        <>
          <div className="modals_container_fail_image"></div>
          <div className="modals_container_fail_textsBox">
            <h1>Failed!</h1>
            <p style={{ color: 'red' }}>
              There was an issue, please try again!
            </p>
          </div>
          <div className="modals_container_fail_buttonBox">
            <GoToDashboardButton
              text="GO TO DASHBOARD"
              onClick={() => dispatch(setTransactionFailModalVisible(false))}
            />
          </div>
        </>
      </section>
    </div>
  )
}

// Transaction Success Modal Component
const TransactionSuccessModal = () => {
  const dispatch = useDispatch()
  return (
    <div
      className="all_modals_wrapper"
      onClick={() => dispatch(setTransactionSuccessModalVisible(false))}
    >
      <section className="modals_container modals_container_success">
        <>
          <div className="modals_container_success_image"></div>
          <div className="modals_container_success_textsBox">
            <h1>Chest has been opened!</h1>
            <p>your NFT will be minted into your wallet soon! </p>
          </div>
          <div className="modals_container_success_buttonBox">
            <GoToDashboardButton
              text="Done"
              width={
                (200 * 1900) / window.innerWidth > 400
                  ? 400
                  : (200 * 1900) / window.innerWidth
              }
              onClick={() => {
                dispatch(setTransactionSuccessModalVisible(false))
                window.location.reload()
              }}
            />
          </div>
        </>
      </section>
    </div>
  )
}

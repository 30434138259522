import { useActiveWeb3React } from "../../hooks"
import {useDispatch, useSelector} from "react-redux"
import { AppState } from "../index"
import {addPopup, PopupContent} from "./actions";
import {useCallback} from "react";

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector(
    (state: AppState) => state.application.blockNumber[chainId ?? -1],
  )
}

export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}
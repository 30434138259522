import { useContract, useSDK } from '@thirdweb-dev/react'
import { DAO_ADDRESS } from 'constants/index'
import DAO_ABI from 'abis/DAOABI.json'
import { useCallback } from 'react'
import { formatDurationFromHex } from 'utils/formatDurationFromHex'

export default function useDaoContract() {
  const sdk = useSDK()
  const { contract: DAOContract } = useContract(DAO_ADDRESS, DAO_ABI)

  /**
   * @description global function for getting user account status in Dao contract
   * @param address user's wallet address
   * @returns an object containing user's knight locked amount, locked time duration, voting power and loyalty
   */
  const getUserStats = useCallback(
    async (address: string) => {
      if (DAOContract)
        try {
          const args = [address]
          const response = await DAOContract.call('calculateBonusCoef', args)
          const result = {
            coef: Number(response.coef) / 1e18,
            lockedAmount: Number(response.lockedAmount) / 1e18,
            lockedTime: formatDurationFromHex(response.lockedTime),
            votingPower: Number(response.votingPower) / 1e18,
          }
          return result
        } catch (err) {
          console.error(err)
          return {
            coef: 0,
            lockedAmount: 0,
            lockedTime: '',
            votingPower: 0,
          }
        }
    },
    [DAOContract],
  )

  /**
   * @description function for getting number of all created proposals
   * @returns Whole number of prposal established in platform
   */
  const getProposalsCount = useCallback(async () => {
    if (DAOContract)
      try {
        const proposalsCount = await DAOContract.call('mappingNonce')
        return proposalsCount.toNumber()
      } catch (err) {
        console.error(err)
        return 0
      }
  }, [DAOContract])

  /**
   * @description This function gets user's integration in a proposal
   * @param proposal The id of proposal
   * @param address User's wallet adress
   * @returns user's claimed reward from voting in the proposal, user's vote power in proposal
   */
  const getMyVote = useCallback(
    async (proposal: number, address: string) => {
      if (DAOContract)
        try {
          const args = [proposal, address]
          const response = await DAOContract.call('voteStatus', args)
          const result = {
            claimedReward: Number(response.claimedReward) / 1e18,
            votePower: Number(response.votePower),
          }
          return result
        } catch (e) {
          console.error(e)
          return 'error'
        }
    },
    [DAOContract],
  )

  /**
   * @description This function will calculate user's claiming value for staking knight
   * @param proposalsCount Number of all proposals created in the protocol
   * @param address User's wallet address
   * @returns The value that user can withdraw
   */
  const getClaimingReward = useCallback(
    async (address: string, proposalsCount: number) => {
      if (DAOContract)
        try {
          const proposalsArray = Array.from(Array(proposalsCount).keys())
          const args = [address, proposalsArray]
          const claimingReward = await DAOContract.call(
            'batchCalculateClaimingReward',
            args,
          )
          return Number(claimingReward) / 1e18
        } catch (err) {
          console.error(err)
          return 0
        }
    },
    [DAOContract],
  )

  /**
   * @description This function claim user's reward for staking and participating in proposlas
   * @param proposalsCount number of all proposlas created in protocol
   * @returns Transaction receipt or error
   */
  const claimRewards = useCallback(async (proposalsCount: number) => {
    try {
      const array = Array.from(Array(proposalsCount).keys())
      const args = [array]
      const contract = await sdk.getContract(DAO_ADDRESS)
      const tx = await contract.call('batchClaimReward', args)
      return tx
    } catch (e) {
      console.error(e)
      return 'error'
    }
  }, [sdk])

  /**
   * @description This function gets user's vote option on proposal and interact to DAO contract
   * @param proposalId The proposal Id
   * @param whichOption The option user decided to vote on
   */
  const vote = useCallback(async (proposalId: number, whichOption) => {
    try {
      const contract = await sdk.getContract(DAO_ADDRESS)
      const args = [proposalId, [whichOption], [100]]
      const response = await contract.call('vote', args)
      return response
    } catch (err) {
      console.error(err)
      return 'error'
    }
  }, [sdk])

  return {
    getUserStats,
    getProposalsCount,
    getClaimingReward,
    claimRewards,
    getMyVote,
    vote,
  }
}

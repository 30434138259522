import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import application from './application/reducer'
import { updateVersion } from './global/actions'
import multicall from './multicall/reducer'
import modalReducers from '../redux/slices/modalSlice'
import marketplaceReducers from './slices/marketplaceSlices'
import nftSlice from './slices/nftSlice'
import transactions from './transactions/reducer'
import StakingFilterReducers from '../components/Staking/stakingSlices/StakingFilterSlice'
import voteDetailPageReducer from '../components/Staking/stakingSlices/VoteDetailSlice'
import StakingModalsReducers from '../components/Staking/stakingSlices/ModalsSlice'
import ChestModalreducers from '../components/Staking/stakingSlices/ModalSlicesChest'
import MegeProgressReducers from '../components/Merge/Slices/MegeProgressSlice'
import SpecialMergeReducer from './slices/SpeicalMergeSlice'
import { useDispatch } from 'react-redux'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']

const store = configureStore({
  reducer: {
    application,
    multicall,
    modalState: modalReducers,
    transactions,
    // menuState: menuReducer,
    marketplaceState: marketplaceReducers,
    nftState: nftSlice,
    StakingFilter: StakingFilterReducers,
    votePageDetail: voteDetailPageReducer,
    stakingModals: StakingModalsReducers,
    MegeProgress: MegeProgressReducers,
    SpecialMergeReducer: SpecialMergeReducer,
    ChestBurnModal: ChestModalreducers,
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: true, immutableCheck: false }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => any = useDispatch

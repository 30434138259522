import axios from 'axios'

//'https://forestknight-api-494325.nonceblox.com'
// 'http://localhost:9010'

// custom axios config

const instance = axios.create({
  // baseURL: 'https://forestknight-api-494325.nonceblox.com',
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
})

export default instance

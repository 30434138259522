import { createSlice } from '@reduxjs/toolkit'

/**
 * @description Storing the redux store config for the modal
 */

export const modalStateSlice = createSlice({
  name: 'modalState',
  initialState: {
    purchaseModalBalanceError: false,
    cancelAuctionModal: false,
    withdrawModal: false,
    NFTid: '',
    NFTname: 'string',
    tokenId: 0,
    modalInfo: {},
  },
  reducers: {
    toggleCancelAuctionModal: (
      state,
      { payload }: { payload: any | undefined },
    ) => {
      state.cancelAuctionModal = !state.cancelAuctionModal
      state.modalInfo = payload || {}
    },
    toggleWithDrawModal: (state, { payload }: { payload: any | undefined }) => {
      state.withdrawModal = !state.withdrawModal
      state.modalInfo = payload || {}
    },
    togglePurchaseModalNoBalance: (state) => {
      state.purchaseModalBalanceError = !state.purchaseModalBalanceError
    },
  },
})

export const {
  toggleCancelAuctionModal,
  toggleWithDrawModal,
  togglePurchaseModalNoBalance,
} = modalStateSlice.actions

export default modalStateSlice.reducer

import classes from './Spinner.module.scss'

/**
 *
 * @returns the custom spinner UI-component
 */

export default function Spinner() {
  return (
    <div className={classes.ring}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

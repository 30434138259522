import { useEffect, useState } from 'react'
import rarityColors from '../data/constants.json'

/**
 * @deprecated
 */

import {
  Monster,
  Arcanist,
  ArcherIcon,
  Witch,
  torso,
  Thief,
  SamuraiIcon,
  RaiderIcon,
  PriestIcon,
  Paladin,
  Ninja,
  MageIcon,
  InfernalSword,
  Golden,
  Coin,
  Equip,
  Barbarian,
  AssassinIcon,
  Vector,
  warrior,
  Livia,
  Wukong,
  SpartanIcon,
  ShamanIcon,
  All,
} from '../assets/index'
import { useHistory } from 'react-router'
import axios from 'axios'

export interface StatsProps {
  health?: number
  attack?: number
  value?: number
  defense?: number
  name?: string
}

export interface PriceProps {
  coins?: number
  dollars?: number
}

export interface CardProps extends dataProp {}

export interface TimeLeftProps {
  day?: number
  hr?: number
  min?: number
}

export interface ListingProps {
  price: PriceProps
  bids?: number
}

export interface ActiveListingProps extends ListingProps {
  timeLeft: TimeLeftProps
  progress: number
  data?: any
  usd?: number
}

export interface SoldProps extends ListingProps {
  timeUpdated: number
  soldTo: string
}

export interface UnsoldProps extends ListingProps {
  timeUpdated: number
}

export interface ProgressProps {
  value: number
  timeLeft: TimeLeftProps
}

export interface ButtonsProps {
  username: string
  state: string
  ownedBy: string
  id?: string
  sell?: boolean
}

export interface MarketPlaceProps {
  state: string
  stats?: StatsProps
  price: PriceProps
  forMarketPlace?: boolean
  data: any
}

export interface dataProp {
  name: string
  id: string
  ownedBy: string
  state: string
  bandColor?: string
  stats?: StatsProps
  price?: PriceProps
  bids?: number
  timeLeft: TimeLeftProps
  progress: number
  timeUpdated: number
  soldTo: string
  rarity?: RarirtyProps
  contractAddress?: string
  blockchain?: string
  about?: string[]
  supply?: number
  saleHistory?: SaleHistoryProps[]
  hero?: string
  heroImg?: string
  uniqueTokenId: string
  image?: string
}

export interface SaleHistoryProps {
  event: string
  price: string
  from: string
  to: string
  date: string
}

export type RarirtyProps =
  | 'Uncommon'
  | 'Rare'
  | 'Special'
  | 'Epic'
  | 'Legendary'
  | 'Mythic'

export type PageType = 'BUY' | 'SELL'

export const heroes = [
  { image: All, option: 'All' },
  { image: warrior, option: 'ForestKnight' },
  { image: Witch, option: 'Witch' },
  { image: Arcanist, option: 'Arcanist' },
  { image: ArcherIcon, option: 'ElfArcher' },
  { image: PriestIcon, option: 'Priest' },
  { image: AssassinIcon, option: 'Assassin' },
  { image: MageIcon, option: 'Mage' },
  { image: Thief, option: 'Thief' },
  { image: Barbarian, option: 'Barbarian' },
  { image: Paladin, option: 'Paladin' },
  { image: RaiderIcon, option: 'Raider' },
  { image: Ninja, option: 'Ninja' },
  { image: SamuraiIcon, option: 'Samurai' },
  { image: Livia, option: 'Livia' },
  { image: Wukong, option: 'Wukong' },
  { image: SpartanIcon, option: 'Damaratus' },
  { image: ShamanIcon, option: 'Shaman' },
]

export const heroes_v2 = {
  All: All,
  'Forest Knight': warrior,
  'ForestKnight': warrior,
  Witch: Witch,
  Arcanist: Arcanist,
  ElfArcher: ArcherIcon,
  Priest: PriestIcon,
  Assassin: AssassinIcon,
  Mage: MageIcon,
  Thief: Thief,
  Barbarian: Barbarian,
  Paladin: Paladin,
  Raider: RaiderIcon,
  Ninja: Ninja,
  Samurai: SamuraiIcon,
  Livia: Livia,
  Wukong: Wukong,
  Damaratus: SpartanIcon,
  Shaman: ShamanIcon,
}

export const prestige = [
  { option: '0' },
  { option: '1' },
  { option: '2' },
  { option: '3' },
  { option: '4' },
  { option: '5' },
]

export const getDefaults = (nft: dataProp) => ({
  ...nft,
  bandColor: rarityColors[nft.rarity || 'Uncommon'],
  heroImg: getHeroImage(nft.hero || 'ForestKnight'),
})

export const getHeroImage = (hero: string) =>
  heroes.find((h) => h.option === hero)?.image || ''

const useNFT = (id: string | null) => {
  const history = useHistory()

  async function getNFTById(id: string | null) {
    const url = `${process.env.REACT_APP_SERVER_URL}/getNFTInfo/${id}`
    // fetch data from url
    const response = await axios.get(url)

    return response.data[0]
  }

  const getNFT = async () => {
    const nft = await getNFTById(id)
    if (!nft && id !== 'loading') {
      history.push('/')
      return
    } else if (id === 'loading') return
    setNFT(getDefaults(nft))
  }
  const [NFT, setNFT] = useState<any>(undefined)
  // @ts-ignore
  useEffect(() => {
    ;(async () => {
      await getNFT()
    })()
  }, [id])

  return NFT
}
export default useNFT

import { createSlice } from '@reduxjs/toolkit'
import { proposal } from './StakingFilterSlice'

interface initial_state {
  checked: boolean
  isLock: boolean
  stakeModalValue: number
  stakeModalVisible: boolean
  stakeModalSuccess: boolean
  stakeModalFailure: boolean
  claimingModal: {
    visible: boolean
    success: boolean
    fail: boolean
  }
  voteModal: {
    data: proposal
    visible: boolean
    success: boolean
    fail: boolean
  }
}

const initialState: initial_state = {
  checked: false,
  isLock: true,
  stakeModalValue: 0,
  stakeModalVisible: false,
  stakeModalSuccess: false,
  stakeModalFailure: false,
  claimingModal: {
    visible: false,
    success: false,
    fail: false,
  },
  voteModal: {
    data: {
      type: 'Active',
      proposalID: 0,
      votingDeadline: 0,
      rewardDistributionDeadline: 0,
      knightReward: 0,
      numberOfOptions: 0,
      startTime: 0,
      optionsVoteStatus: [0, 0],
      totalVote: 0,
      title: '',
      description: '',
      imageURL: '',
      options: ['yes', 'no'],
    },
    visible: false,
    success: false,
    fail: false,
  },
}

export const StakingModalsSlice = createSlice({
  name: 'StakingModals',
  initialState,
  reducers: {
    // stake modal ------------------------------------------------------------------

    setChecked: (state) => {
      state.checked = !state.checked
    },

    setStakingValue: (state, { payload }: { payload: number }) => {
      state.stakeModalValue = payload
    },

    setStakeModalVisible: (state, { payload }: { payload: boolean }) => {
      state.stakeModalVisible = !state.stakeModalVisible
      state.isLock = !payload
      state.stakeModalFailure = false
      state.stakeModalSuccess = false
    },

    setStakeModalStatus: (
      state,
      { payload }: { payload: 'success' | 'fail' },
    ) => {
      if (payload === 'success') {
        state.stakeModalSuccess = true
      } else {
        state.stakeModalFailure = true
      }
    },

    // claim modal ------------------------------------------------------------------

    setClaimingModalVisible: (state) => {
      if (state.claimingModal.visible === false) {
        state.claimingModal.visible = !state.claimingModal.visible
      } else {
        state.claimingModal.visible = !state.claimingModal.visible
        state.claimingModal.success = false
        state.claimingModal.fail = false
      }
    },
    setClaimingModalStatus: (
      state,
      { payload }: { payload: 'success' | 'fail' },
    ) => {
      if (payload === 'success') {
        state.claimingModal.success = true
      } else {
        state.claimingModal.fail = true
      }
    },

    // vote modal ------------------------------------------------------------------

    setVoteModalVisible: (state) => {
      state.voteModal.visible = !state.voteModal.visible
      state.voteModal.success = false
      state.voteModal.fail = false
      
    },

    setVoteModalData: (state, { payload }: { payload: proposal }) => {
      state.voteModal.data = payload
    },

    setVoteModalSuccess: (state) => {
      state.voteModal.success = !state.voteModal.success
    },

    setVoteModalFail: (state) => {
      state.voteModal.fail = !state.voteModal.fail
    },
  },
})

export const {
  setStakingValue,
  setStakeModalVisible,
  setStakeModalStatus,
  setChecked,
  setClaimingModalVisible,
  setClaimingModalStatus,
  setVoteModalVisible,
  setVoteModalData,
  setVoteModalSuccess,
  setVoteModalFail,
} = StakingModalsSlice.actions

export default StakingModalsSlice.reducer

import { NavType } from 'constants/header-items'
import { useEffect, useRef, useState } from 'react'
import Arrow from '../Arrow'

const NavButton: React.FC<{
  navItem: NavType
  history: any
  pathname: string
}> = ({ navItem, history, pathname }) => {
  const [clicked, setClicked] = useState(false)
  const navButtonRef = useRef<HTMLLIElement>(null)

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation()
    if (navItem.link.href) {
      window.open(navItem.link.href, '_blank')
    } else if (navItem.link.pathname !== '#') {
      history.push(navItem.link.pathname)
      if (navItem.subMenus) setClicked(!clicked)
    } else {
      setClicked(!clicked)
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        navButtonRef.current &&
        !navButtonRef.current.contains(e.target as Node)
      ) {
        setClicked(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <li
      ref={navButtonRef}
      className={`header_button ${
        pathname === navItem.link.pathname
          ? 'header_button_path_checked'
          : pathname === '/chest-steampunk' || pathname === '/chest-clockwork'
          ? navItem.label === 'Chests' && 'header_button_path_checked'
          : pathname === '/merge' || pathname === '/forge'
          ? navItem.label === 'Crafting' && 'header_button_path_checked'
          : pathname === '/profile-v1' || pathname === '/profile-v2'
          ? navItem.label === 'Profile' && 'header_button_path_checked'
          : pathname === '/marketplace-v1' || pathname === '/marketplace-v2'
          ? navItem.label === 'Marketplace' && 'header_button_path_checked'
          : null
      }`}
      style={{
        color:
          pathname === navItem.link.pathname
            ? '#edaa38'
            : pathname === '/chest-steampunk' || pathname === '/chest-clockwork'
            ? navItem.label === 'Chests' && '#edaa38'
            : pathname === '/forge' || pathname === '/merge'
            ? navItem.label === 'Crafting' && '#edaa38'
            : pathname === '/profile-v1' || pathname === '/profile-v2'
            ? navItem.label === 'Profile' && '#edaa38'
            : pathname === '/marketplace-v1' || pathname === '/marketplace-v2'
            ? navItem.label === 'Marketplace' && '#edaa38'
            : '',
      }}
      onClick={handleClick}
    >
      <navItem.icon />
      {navItem.label}
      {navItem.subMenus && <Arrow clicked={clicked} />}
      {navItem.subMenus && clicked && (
        <div className="header_button_dropdown">
          {navItem.subMenus.map((item, index) => (
            <div
              style={{
                borderRadius:
                  navItem.subMenus.length === 1
                    ? '5px'
                    : index === navItem.subMenus.length - 1
                    ? '0 0 5px 5px'
                    : index === 0
                    ? '5px 5px 0 0'
                    : '',
              }}
              className="header_button_dropdown_item"
              key={item.label}
              onClick={() => {
                if (item.link) {
                  if (item.link.href) {
                    window.open(item.link.href, '_blank')
                  } else {
                    history.push(item.link.pathname)
                  }
                }
              }}
            >
              <item.icon />
              <p>
                {item.label}
                {item.soon && ' [Soon]'}
              </p>
            </div>
          ))}
        </div>
      )}
    </li>
  )
}

export default NavButton

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_ring__Mz93F {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.Spinner_ring__Mz93F div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: Spinner_lds-ring__GQK7- 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.Spinner_ring__Mz93F div:nth-child(1) {
  animation-delay: -0.45s;
}

.Spinner_ring__Mz93F div:nth-child(2) {
  animation-delay: -0.3s;
}

.Spinner_ring__Mz93F div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes Spinner_lds-ring__GQK7- {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AACA;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,6EAAA;EACA,sDAAA;AAEF;;AAAA;EACE,uBAAA;AAGF;;AADA;EACE,sBAAA;AAIF;;AAFA;EACE,uBAAA;AAKF;;AAHA;EACE;IACE,uBAAA;EAMF;EAJA;IACE,yBAAA;EAMF;AACF","sourcesContent":[".ring {\n  display: inline-block;\n  position: relative;\n  width: 40px;\n  height: 40px;\n}\n.ring div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 24px;\n  height: 24px;\n  margin: 8px;\n  border: 4px solid #fff;\n  border-radius: 50%;\n  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #fff transparent transparent transparent;\n}\n.ring div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n.ring div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n.ring div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ring": `Spinner_ring__Mz93F`,
	"lds-ring": `Spinner_lds-ring__GQK7-`
};
export default ___CSS_LOADER_EXPORT___;
